import React, {useState, useEffect} from 'react';

// reactstrap components

import {useCheckAuth} from 'hooks/useCheckAuth';

// core components
import TopNavbar from 'components/Navbars/TopNavbar';
import EmptyList from 'components/EmptyList';
import CustomTable from 'components/CustomTable';

import useFetchList from 'hooks/useFetchList';

import {requestsUrl} from '../../api/endpoints';
import * as tableConfig from './tableConfig';

import '../../sinistres-styles.scss';
import 'assets/css/listviews.css';

function RequestsList(props) {
  useCheckAuth();
  const [fetchParams, setFecthParams] = useState({skip: 0, top: 10});
  const [apiEndpoint, setEndpoint] = useState(requestsUrl(''));
  const {data, count, fetching} = useFetchList(apiEndpoint);

  useEffect(() => {
    const urlQuery = `$skip=${fetchParams.skip}&$top=${fetchParams.top}`;
    setEndpoint(requestsUrl(urlQuery));
  }, [apiEndpoint, fetchParams]);

  return (
    <div className="app-container">
      <TopNavbar isConnected={props.currentUser !== null} />
      <div className="app-content requests-list">
        <h3 className="demande-title-text">Mes Déclarations Sinistres</h3>
        <div className="request-table">
          {data.length === 0 ? (
            <EmptyList fetching={fetching} text={'Aucune donnée trouvée'} />
          ) : (
            <CustomTable
              columns={tableConfig.columns}
              dataSource={data}
              cellKey={'_id'}
              rowClickable
              onRowPress={(item) => {
                props.actions.setCurrentSinister(item);
                props.history.push('/sinistres/details');
              }}
              pagination={{
                count,
                rowsPerPage: fetchParams.top,
                page: Math.round(fetchParams.skip / fetchParams.top),
              }}
              handleChangePage={(params) => {
                setFecthParams(params);
              }}
              handleChangeRowsPerPage={(params) => {
                setFecthParams(params);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RequestsList;
