import Step1 from './containers/infos';
import Step2 from './containers/fichiers';
import Step3 from './containers/confirm';

export const steps = [
  {
    label: 'Informations',
    hash: '#infos',
    index: 0,
    component: Step1,
  },
  {label: 'Pièces Jointes', hash: '#fichiers', index: 1, component: Step2},
  {label: 'Confirmation', hash: '#confirm', index: 2, component: Step3},
];
