import {useState, useEffect} from 'react';
import {omitBy} from 'lodash';

import {useSelector} from 'react-redux';

import {Api} from 'api';

import {fichiersStateSelect} from '../selector';

export function useFetchCarte(carte, cb) {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const {devis} = useSelector(fichiersStateSelect);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(`/cartes/${carte}`);
        const garanties = omitBy(data, (one) => !one.isEnabled);
        let initSelection = garanties;
        Object.keys(garanties).forEach((g) => {
          const wasSelected = devis?.carteData?.[g]?.isEnabled || false;
          initSelection = {
            ...initSelection,
            [`${g}`]: {
              ...garanties[g],
              selected: wasSelected,
            },
          };
        });

        setData(initSelection);
        cb(initSelection);
      } catch (e) {
        console.log('e', e);
        // maybe display an error message
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [carte, cb, devis]);

  return {fetching, data};
}
