import React from 'react';

// reactstrap components
import {Button, Card, CardBody, CardFooter, Col} from 'reactstrap';

// core components
import AuthNavbar from 'components/Navbars/AuthNavbar';

import {useCheckAuth} from 'hooks/useCheckAuth';

import './landing-styles.scss';

function HomePage(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {};
  }, []);

  const startOrder = (e) => {
    if (props.currentUser) {
      props.actions.clearCreationForm();
      props.history.push('/devis#user');
    } else {
      props.history.push('/auth/signin');
    }
  };

  const startSinister = (e) => {
    if (props.currentUser) {
      props.actions.clearCreationForm();
      props.history.push('/declaration#infos');
    } else {
      props.history.push('/auth/signin');
    }
  };

  return (
    <div className="app-container landing">
      <AuthNavbar landing />
      <div className="app-content bg-image landing-content">
        <Col md="6">
          <Card className="card-login card-plain">
            <CardBody>
              <p className="text-black text-bold portal-title">
                Découvrez le portail AGMA
              </p>
              <h4 className="text-black">
                Vos assurances Auto en toute simplicité
              </h4>
            </CardBody>
            <CardFooter className="text-left">
              <Button
                block
                className="btn-round on-secondary"
                color="secondary"
                onClick={startOrder}
                size="lg">
                Mon Devis en 5 minutes
              </Button>
            </CardFooter>
            <CardFooter className="text-left">
              <Button
                block
                className="btn-round on-secondary"
                color="primary"
                onClick={startSinister}
                size="lg">
                Déclarer mon Sinistre
              </Button>
            </CardFooter>
            <CardFooter className="text-left">
              <div className="text-black" style={{marginTop: '45%'}}>
                <img
                  width="100px"
                  alt="..."
                  src={require('assets/img/Picture1.png')}
                />{' '}
                <span className="text-black" style={{marginTop: '45%'}}>
                  © Copyright 2021 AGMA - Tous Droits réservés -
                  <a
                    href=" https://agma.ma/mentions-legales-2/"
                    target="_blank">
                    {' '}
                    Mentions légales
                  </a>
                </span>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </div>
    </div>
  );
}

export default HomePage;
