import React, {useState} from 'react';
import {Button} from 'reactstrap';

import {downloadFile} from 'lib/helpers';

import StepFooter from '../../stepFooter';
import {useDownloadPdf} from 'hooks/useDownloadPdf';
import {useCreateDevis} from 'hooks/useCreateDevis';

export default function Recap(props) {
  const [pdfUrl, setPdfUrl] = useState('');
  const [{fetching: updateFetching}, createDevis] = useCreateDevis();
  const {fetching, data} = useDownloadPdf(
    `/requests/${props.devis?._id}/report`,
    setPdfUrl,
  );
  const onSubmit = () => {
    downloadFile(pdfUrl);
  };

  const onConfirm = () => {
    createDevis({statut: 'CONFIRMED_WITHOUT_PJ'}, '/devis#fichiers', true);
  };

  return (
    <div className="step-container">
      <div>
        <h3 className="page-title-text">Confirmation du devis</h3>
        <iframe
          id="pdf-preview"
          title="Devis Report"
          src={pdfUrl}
          type="application/pdf"
          width="100%"
          height="400px"></iframe>
        <div className="text-center">
          <Button
            className="btn-round"
            color="default"
            onClick={(e) => {
              onSubmit();
            }}
            size="lg">
            Imprimer
          </Button>
        </div>
      </div>
      <StepFooter
        onPressPrevious={() => {
          props.actions.clearStepForm();
          props.actions.setCreatedDevis(null);
          props.history.go(-1);
        }}
        onPressNext={() => onConfirm()}
        next="/devis#fichiers"
        //nextDisabled={invalidForm}
        fetching={updateFetching}
        nextText="Confirmer le devis"
        history={props.history}
        onCancel={props.onCancel}
      />
    </div>
  );
}
