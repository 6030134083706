// routes components
// import App from 'modules/App';
import Auth from 'modules/Auth';
import HomePage from 'modules/HomePage';
import Landing from 'modules/Landing';
import AppLoader from 'modules/AppLoader';
import MonEspace from 'modules/MonEspace';
import Declaration from 'modules/Declaration';
import DeclarationSuccess from 'modules/Declaration/containers/success';
import Devis from 'modules/Devis';
import Demandes from 'modules/Demandes';
import PasswordReset from 'modules/PasswordReset';
import DevisSuccess from 'modules/Devis/containers/success';
import Sinistres from 'modules/Sinistres';

const routes = [
  {
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    path: '/loader',
    component: AppLoader,
    exact: true,
  },
  // {
  //   path: '/landing',
  //   component: Landing,
  //   exact: true,
  // },
  {
    path: '/resetpass',
    component: PasswordReset,
    exact: true,
  },
  {
    path: '/my-space',
    component: MonEspace,
    exact: true,
  },
  {
    path: '/auth',
    component: Auth,
  },
  {
    path: '/devis',
    component: Devis,
  },
  {
    path: '/success',
    component: DevisSuccess,
    exact: true,
  },
  {
    path: '/requests',
    component: Demandes,
  },
  {
    path: '/declaration',
    component: Declaration,
  },
  {
    path: '/declaration-success',
    component: DeclarationSuccess,
    exact: true,
  },
  {
    path: '/sinistres',
    component: Sinistres,
  },
];

export default routes;
