export function createUrl() {
  return '/sinistres';
}
export function createPdfDeclarationUrl() {
  return '/sinistres/declaration';
}

export function devisById(id) {
  return `/sinistres/${id}`;
}

export function getSinisters() {
  return 'sinistres';
}
