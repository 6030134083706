import {useState, useEffect} from 'react';

import {Api} from 'api';

export default function useFetchElement(el, endpoint, cb) {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(el);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(endpoint);
        setFetching(false);
        setData(data);
        if (cb) {
          cb();
        }
      } catch {
        setFetching(false);
        // maybe display an error message
      }
    };

    fetchData();
  }, [cb, endpoint]);

  return {fetching, data};
}
