import {createSelector} from 'reselect';

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser,
);

export const userDataStateSelect = createSelector(
  (state) => state.devis,
  (devis) => devis.user,
);

export const vehiculeStateSelect = createSelector(
  (state) => state.devis,
  (devis) => devis.vehicule,
);

export const fichiersStateSelect = createSelector(
  (state) => state.devis,
  (devis) => devis.fichiers,
);

export const garantiesStateSelect = createSelector(
  (state) => state.devis,
  (devis) => devis.garanties,
);
