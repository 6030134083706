// import front-end config
import {CLEAR_DECLARATION_FORM, UPDATE_FILES_FORM} from '../actionsTypes';
import {LOGOUT_SUCCESS} from 'actionsTypes';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  files: null,
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function fichiers(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILES_FORM:
      return {...state, files: action.payload};

    case CLEAR_DECLARATION_FORM:
    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
