import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../../actions';

import {
  confirmStateSelect,
  currentUserStateSelect,
  infosStateSelect,
} from '../../selector';

import Confirm from './component';

const ConfirmContainer = (props) => {
  const currentUser = useSelector(currentUserStateSelect);
  const confirm = useSelector(confirmStateSelect);
  const info = useSelector(infosStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  return (
    <Confirm
      currentUser={currentUser}
      infos={info}
      confirm={confirm}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default ConfirmContainer;
