import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Api} from 'api';

import {garantiesStateSelect} from '../selector';

export function useFetchConvention(convention, cb) {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState({});

  const {configType} = useSelector(garantiesStateSelect);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(`/conventions/${convention}`);
        setData(data);
        const type = data.hasCarte ? 'carte' : 'pack';
        const initType = data.hasPacks && data.hasCarte ? '' : type;
        cb(initType || configType);
      } catch {
        // maybe display an error message
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [cb, configType, convention]);

  return {fetching, data};
}
