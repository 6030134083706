import {composeWithDevTools} from 'redux-devtools-extension';
import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import createReducers from 'reducers';
import {createHashHistory} from 'history';
import storage from 'redux-persist/lib/storage';
import {persistStore, persistReducer} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth'],
};

export const history = createHashHistory({
  hashType: 'slash',
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
});

const persistedReducer = persistReducer(persistConfig, createReducers(history));

export default function configureStore() {
  let enhancer;

  if (process.env.NODE_ENV === 'development') {
    enhancer = composeWithDevTools(
      applyMiddleware(thunk, routerMiddleware(history)),
    );
  } else {
    enhancer = compose(applyMiddleware(thunk, routerMiddleware(history)));
  }

  const store = createStore(
    persistedReducer, // root reducer with router state
    enhancer,
  );

  const persistor = persistStore(store);

  if (module.hot) {
    // Enable hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer(persistedReducer);
    });
  }

  return {store, persistor};
}
