// routes components
import List from './containers/list';
import Details from './containers/details';

const routes = [
  {
    path: '/list',
    component: List,
    exact: true,
  },
  {
    path: '/details',
    component: Details,
    exact: true,
  },
];

export default routes;
