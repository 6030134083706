import React from 'react';
import {Route, Switch, Redirect} from 'react-router';

import routes from './routes';

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => {
        if (props.location.pathname === '/' && props.location.search) {
          return <Redirect to={`/loader?${props.location.search}`} />;
        }
        return (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        );
      }}
    />
  );
}

function App(props) {
  console.log('app props', props);
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}

export default App;
