import React from 'react';

import 'assets/css/statusTag.css';

const Tag = (props) => {
  return (
    <div className={`status-tag ${props.status}`}>
      <span className="status-label">{props.label}</span>
    </div>
  );
};

export default Tag;
