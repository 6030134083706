import React from "react";
import { Spinner } from "reactstrap";

const EmptyList = (props) => {
  const { fetching, text } = props;
  return (
    <div className="empty-list-view">
      {fetching ? (
        <Spinner />
      ) : (
        <>
          <img
            alt="..."
            src={require("assets/img/empty.png")}
            className="empty-icon"
          />
          <h4> {text} </h4>{" "}
        </>
      )}
    </div>
  );
};

export default EmptyList;
