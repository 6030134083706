// import front-end config
import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_FAILED,
  SET_CURRENT_USER,
  SET_EMPLOYER,
  LOGOUT_SUCCESS,
} from '../actionsTypes';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  currentUser: null,
  employer: null,
  fetchingUser: false,
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_USER_REQUEST:
      return {...state, fetchingUser: true};

    case GET_CURRENT_USER_FAILED:
      return {...state, fetchingUser: false};

    case SET_EMPLOYER:
      return {...state, employer: action.payload};

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: state.currentUser
          ? {
              ...action.payload,
              employer: action.payload?.employer?.convention
                ? action.payload?.employer
                : state.currentUser?.employer,
            }
          : action.payload,
        fetchingUser: false,
        isGuest: false,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
