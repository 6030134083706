import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../../actions';

import {
  garantiesStateSelect,
  userDataStateSelect,
  vehiculeStateSelect,
  currentUserStateSelect,
} from '../../selector';

import Garanties from './component';

const GarantiesContainer = (props) => {
  const currentUser = useSelector(currentUserStateSelect);
  const userData = useSelector(userDataStateSelect);
  const vehicule = useSelector(vehiculeStateSelect);
  const garanties = useSelector(garantiesStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  return (
    <Garanties
      garanties={garanties}
      currentUser={currentUser}
      userData={userData}
      vehicule={vehicule}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default GarantiesContainer;
