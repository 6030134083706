export const CLEAR_CREATION_FORM = 'CLEAR_CREATION_FORM';
export const UPDATE_USERDATA_FORM = 'UPDATE_USERDATA_FORM';
export const UPDATE_VEHICULE_DATA_FORM = 'UPDATE_VEHICULE_DATA_FORM';

export const SET_CREATED_DEVIS = 'SET_CREATED_DEVIS';
export const SAVE_DEFAULT_ENABLED_CODES = 'SAVE_DEFAULT_ENABLED_CODES';
export const SET_INTERDEPENDANCES = 'SET_INTERDEPENDANCES';

export const UPDATE_GARANTIES_FORM = 'UPDATE_GARANTIES_FORM';

export const CLEAR_STEP3_FORM = 'CLEAR_STEP3_FORM';
export const SET_WITH_G11 = 'SET_WITH_G11';
