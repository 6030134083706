import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../../actions';
import {authStateSelect, userStateSelect} from '../../selector';

import InfoView from './component';

const InfoViewContainer = (props) => {
  const auth = useSelector(authStateSelect);
  const currentUser = useSelector(userStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  return (
    <InfoView
      auth={auth}
      currentUser={currentUser}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default InfoViewContainer;
