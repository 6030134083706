import React, {useState, useEffect} from 'react';
import {Api} from 'api';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {Redirect} from 'react-router';

import {
  setCurrentUser,
  getCurrentUserRequest,
  getCurrentUserFailed,
} from 'actions';
import {push} from 'connected-react-router';

const userStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser,
);

// const userFecthStateSelect = createSelector(
//   (state) => state.app,
//   (app) => app.fetchingUser,
// );

export function useCheckAuth() {
  const currentUser = useSelector(userStateSelect);
  // const fetching = useSelector(userFecthStateSelect);
  const [isConnected, setConnected] = useState(currentUser !== null);
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAuth() {
      try {
        dispatch(getCurrentUserRequest());
        const user = await Api().get('/me?$expand=employer');
        console.log('got user', user);
        setConnected(user !== null);
        dispatch(setCurrentUser(user));
        if (!user) {
          dispatch(push('/auth'));
        }
      } catch (e) {
        console.log('e', e);
        setConnected(false);
        dispatch(getCurrentUserFailed());
      } finally {
        setIsReady(true);
      }
    }

    checkAuth();
  }, [dispatch]);

  return {isConnected, isReady};
}

export default function CheckAuth(Component, ...props) {
  const {isConnected, isReady} = useCheckAuth();
  if (isReady) {
    return isConnected ? (
      <Component isConnected={isConnected} {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/',
          state: {from: props.location},
        }}
      />
    );
  }

  return <div />;
}
