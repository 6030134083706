import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';

import {Api} from 'api';

import {createUrl} from '../api/endpoints';
import {fichiersStateSelect, infosStateSelect} from '../selector';
import moment from 'lib/moment';

export function useCreateSinister() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const {files} = useSelector(fichiersStateSelect);
  const infos = useSelector(infosStateSelect);

  async function createDevis(payload, redirect) {
    try {
      setFetching(true);
      setError(null);
      const createPayload = {
        ...payload,
        ...(infos.matricule1 && {
          immatriculation: infos.isNew
            ? `${infos.matricule1}-WW`
            : `${infos.matricule1}-${infos.matricule2.value}-${infos.matricule3}`,
        }),
        files,
        attestation: infos.attestation,
        cnie: infos.cnie,
        phone: infos.phone,
        date: infos.dateEffet
          ? moment(infos.dateEffet).format('YYYY-MM-DD')
          : '',
        hour: infos.timeEffet ? moment(infos.timeEffet).format('HH:MM') : '',
        circonstances: infos.circonstance,
        accident: infos.accident?.value,
        points: infos.points,
      };
      await Api().post(createUrl(), createPayload);

      if (redirect) {
        dispatch(push(redirect));
      }
    } catch (err) {
      setError(err);
    } finally {
      setFetching(false);
    }
  }

  return [{fetching, error}, createDevis];
}
