import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

const VenaleModal = (props) => {
  return (
    <Modal size="lg" isOpen={props.display} toggle={props.onCancel}>
      <ModalBody>
        <span className="popover-title text-underline">Valeur vénale</span>
        <img
          src={require('assets/img/vv.png')}
          style={{width: '800px', height: 'auto', objectFit: 'contain'}}
          alt="v"
        />
        <span className="popover-text">
          Au bout de la 5ème année, les coefficients annuels restent fixes
          jusqu’à la 10ème année. A partir de la 11ème année, le coefficient
          passe à 5%.
        </span>
      </ModalBody>
      <ModalFooter className="notice-footer">
        <div
          className="modal-close-btn"
          tag="button"
          color="secondary"
          onClick={props.onCancel}>
          Fermer
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default VenaleModal;
