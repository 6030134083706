import React, {useState, useEffect} from 'react';

export function usePasswordCheck(password) {
  const [containLetter, setLetter] = useState(false);
  const [containMaj, setMaj] = useState(false);
  const [containDigit, setDigit] = useState(false);
  const [containSpecial, setSpecial] = useState(false);
  const [validLength, setLength] = useState(false);
  useEffect(() => {
    setLetter(/[a-z]/.test(password));
    setMaj(/[A-Z]/.test(password));
    setDigit(/[0-9]/.test(password));
    setSpecial(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~éçàè.§€]/.test(password));
    setLength(password && password.length >= 8);
  }, [password]);

  return {
    containLetter,
    containMaj,
    containDigit,
    containSpecial,
    validLength,
    isValid:
      containLetter &&
      containMaj &&
      containDigit &&
      containSpecial &&
      validLength,
  };
}

const ErrorLabel = ({password}) => {
  const {
    containLetter,
    containMaj,
    containDigit,
    containSpecial,
    validLength,
    isValid,
  } = usePasswordCheck(password);

  return (
    !isValid && (
      <h6 className="login-input-error">
        <span className={containLetter ? 'text-success' : ''}>Miniscule,</span>
        <span className={containMaj ? 'text-success' : ''}>Majuscule,</span>
        <span className={containDigit ? 'text-success' : ''}>Chiffre,</span>
        <span className={containSpecial ? 'text-success' : ''}>
          Caractère spécial,
        </span>
        <span className={validLength ? 'text-success' : ''}>
          min 8 caractères
        </span>
      </h6>
    )
  );
};

export default ErrorLabel;
