import React, {useEffect, useState} from 'react';

import {Col, Row, FormGroup, Label} from 'reactstrap';
import Select from 'react-select';

import {CITIES, PARENTAL_LINK} from 'config/app';
import inputsValidation from 'lib/inputsValidation';

import AppInput from 'components/AppInput';
import ButtonTag from 'components/ButtonTag';
import StepFooter from '../../stepFooter';

export default function UserData(props) {
  const {currentUser, userData} = props;
  const [interested, setInterested] = useState(userData?.interested || '');

  const [state, setState] = useState({
    firstname: userData?.firstname || '',
    lastname: userData?.lastname || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
    city: userData?.city || null,
    relationship: userData?.relationship || null,
  });

  const invalidForm =
    !interested ||
    !state.firstname ||
    !state.lastname ||
    !state.email ||
    !state.phone ||
    !state.city ||
    (interested === 'OTHER' && !state.relationship);

  useEffect(() => {
    if (interested === 'USER') {
      setState({
        firstname: currentUser?.name.first,
        lastname: currentUser?.name.last,
        email: currentUser?.email,
        phone: currentUser?.phone,
        city: currentUser?.city
          ? {label: currentUser?.city, value: currentUser?.city}
          : null,
      });
    } else {
      setState({
        firstname: userData?.firstname || '',
        lastname: userData?.lastname || '',
        email: userData?.email || '',
        phone: userData?.phone || '',
        city: userData?.city || null,
        relationship: userData?.relationship || null,
      });
    }
  }, [currentUser, interested, userData]);

  const onSubmit = () => {
    const emailError = !inputsValidation('email', state.email);
    const phoneError = !inputsValidation('phone', state.phone);
    if (emailError || phoneError) {
      setState({...state, emailError, phoneError});
    } else {
      props.actions.updateUserForm({...state, interested});
      if (interested === 'USER') {
        props.actions.updateUserProfile({phone: state.phone});
      }
      props.history.push('/devis#vehicule');
    }
  };

  return (
    <div className="step-container">
      <h3 className="page-title-text">Mes coordonnées Assuré</h3>
      <div>
        <span className="step-field"> Cette assurance est pour </span>
        <div className="btn-grp-h">
          <ButtonTag
            onClick={() => setInterested('USER')}
            label="Moi même"
            active={interested === 'USER'}
          />
          <div className="other-user-explain">
            <ButtonTag
              onClick={() => setInterested('OTHER')}
              label="Autre"
              active={interested === 'OTHER'}
            />
            <span>(Conjoint, Descendant, Ascendant)</span>
          </div>
        </div>
      </div>
      <div className="step-form">
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Prénom"
              required
              value={state.firstname}
              onChange={(t) => setState({...state, firstname: t})}
            />
          </Col>
          <Col xs="12" sm="4">
            <AppInput
              label="Nom"
              required
              value={state.lastname}
              onChange={(t) => setState({...state, lastname: t})}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Email"
              type="email"
              required
              value={state.email}
              onChange={(t) =>
                setState({
                  ...state,
                  email: t,
                })
              }
              hasError={state.emailError}
            />
          </Col>
          <Col xs="12" sm="4">
            <AppInput
              label="Téléphone"
              required
              value={state.phone}
              onChange={(t) =>
                setState({
                  ...state,
                  phone: t,
                })
              }
              hasError={state.phoneError}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>
                Ville <span>*</span>{' '}
              </Label>
              <Select
                className="react-select custom-input"
                classNamePrefix="react-select"
                onChange={(select) => {
                  setState({...state, city: select});
                }}
                value={state.city}
                options={CITIES}></Select>
            </FormGroup>
          </Col>
          {interested === 'OTHER' && (
            <Col xs="12" sm="4">
              <FormGroup>
                <Label>
                  Lien de parenté <span>*</span>{' '}
                </Label>
                <Select
                  className="react-select custom-input"
                  classNamePrefix="react-select"
                  onChange={(select) => {
                    setState({...state, relationship: select});
                  }}
                  value={state.relationship}
                  options={PARENTAL_LINK}></Select>
              </FormGroup>
            </Col>
          )}
        </Row>
      </div>
      <StepFooter
        previous={false}
        onPressNext={() => onSubmit()}
        next="/devis#vehicule"
        nextDisabled={invalidForm}
        history={props.history}
        onCancel={props.onCancel}
      />
    </div>
  );
}
