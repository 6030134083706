import React from 'react';
import {withRouter} from 'react-router';
import {bool} from 'prop-types';

// reactstrap components
import {Button} from 'reactstrap';

function AuthNavbar(props) {
  const {history, register, landing} = props;

  const onClickBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className="landing-navbar">
      <div className="topbar-right">
        {!landing && (
          <Button
            onClick={onClickBack}
            className="btn-round btn-icon btn-shadow">
            <img
              alt="icon"
              src={
                props.login
                  ? require('assets/img/closeIcon.png')
                  : require('assets/img/arrow-left.png')
              }
              className="back-icon"
            />
          </Button>
        )}
      </div>
      <img
        alt="logo"
        src={require('assets/img/logo.png')}
        className="app-logo"
        onClick={() => history.push('/auth/landing')}
        tag="button"
      />

      <div className="topbar-right">
        {!register && (
          <>
            <div
              onClick={() => {
                history.push('/auth/signin');
              }}
              className="nav-right-item">
              Se connecter
            </div>
            <div
              onClick={() => {
                history.push('/auth/signup');
              }}
              className="nav-right-item">
              S'inscrire
            </div>
          </>
        )}
      </div>
    </div>
  );
}

AuthNavbar.propTypes = {
  login: bool,
  register: bool,
};

export default withRouter(AuthNavbar);
