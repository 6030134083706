import React from 'react';

import {Row, Col, Container, Button} from 'reactstrap';

import PackView from './packView';

export default function PacksList({packs, ...props}) {
  return (
    <div className="packs-container">
      <Container>
        <Row xs="3">
          {packs.map((pack) => (
            <Col key={pack._id}>
              <div key={pack._id} className="pack-view-container">
                <PackView pack={pack} selected={props.selected} />
                <div className="pack-select-btn">
                  <Button
                    className="btn-round"
                    color={
                      props.selected === pack._id ? 'success-dark' : 'success'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      props.onSelectPack(pack._id);
                    }}
                    size="lg"
                    style={{
                      position: 'relative',
                    }}>
                    <span>Séléctionner</span>
                    {props.selected === pack._id && (
                      <img
                        src={require('assets/img/btn-check.png')}
                        alt="v"
                        style={{
                          width: '15px',
                          height: 'auto',
                          objectFit: 'contain',
                          position: 'absolute',
                          right: '20px',
                          bottom: '36%',
                        }}
                      />
                    )}
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Container>
        <Row xs="3">
          {packs.map((pack) => (
            <Col key={pack._id}></Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
