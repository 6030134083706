import React, {useState} from 'react';
import {Row, Col, FormGroup, Label} from 'reactstrap';
import Select from 'react-select';
import classNames from 'classnames';

import {INSURANCES} from 'config/app';
import {downloadFile} from 'lib/helpers';
import downloadApi from 'api/downloadApi';

import {useCreateSinister} from '../../hooks/useCreateSinister';
import {useCreatePdfDeclaration} from '../../hooks/useCreatePdfDeclaration';
import {useDownloadPdf} from 'hooks/useDownloadPdf';

import StepFooter from '../../stepFooter';

export default function Confirming(props) {
  const [compagne, setCompagne] = useState('');
  const [honor_checked, setHonor] = useState(false);

  const [{fetching}, createSinister] = useCreateSinister();
  const [
    {fetchingPdfDeclaration, pdfData},
    createPdfDeclaration,
  ] = useCreatePdfDeclaration();

  const downloadModele = async () => {
    const payload = {
      cmp: compagne?.cmp,
    };
    if (compagne?.cmp) {
      createPdfDeclaration(payload, compagne);
    }
  };

  const onSubmit = () => {
    const payload = {
      compagne: compagne?.value,
      accept: true,
    };
    createSinister(payload, '/declaration-success');
  };

  return (
    <div className="step-container">
      <h3 className="page-title-text">Déclaration d'un sinistre</h3>
      <div className="step-form">
        <Row>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>Veuillez choisir votre compagnie</Label>
              <Select
                className="react-select custom-input"
                classNamePrefix="react-select"
                onChange={(select) => {
                  setCompagne(select);
                }}
                value={compagne}
                options={INSURANCES}></Select>
            </FormGroup>
          </Col>
        </Row>
        <div>
          <FormGroup>
            <Label>
               Nous vous remercions de télécharger votre déclaration ci-après,
              la signer puis nous la transmettre à l’adresse{' '}
              <a href="mailto:SinAuto@agma.co.ma">SinAuto@agma.co.ma</a>
            </Label>
            <div
              onClick={downloadModele}
              className={classNames('file-downloader', {
                disabled: !compagne,
              })}>
              <span>Modèle déclaration</span>
              <img
                src={require('assets/img/download.png')}
                className="download-icon"
                alt="|"
              />
            </div>
          </FormGroup>
        </div>
        <div className="for-honor">
          <span>
            {honor_checked ? (
              <img
                src={require('assets/img/square_check_full.png')}
                alt="o"
                className="info-check-mark"
                role="button"
                onClick={() => setHonor(!honor_checked)}
              />
            ) : (
              <img
                src={require('assets/img/square_check.png')}
                alt="o"
                className="info-check-mark"
                role="button"
                onClick={() => setHonor(!honor_checked)}
              />
            )}
            Je déclare sur l'honneur l'exactitude des informations transmises
          </span>
        </div>
      </div>

      <StepFooter
        onPressNext={() => onSubmit()}
        next="/declaration#success"
        nextDisabled={!compagne || !honor_checked}
        fetching={fetching}
        history={props.history}
        onCancel={props.onCancel}
      />
    </div>
  );
}
