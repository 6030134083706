import React from 'react';
import {Redirect} from 'react-router';
import {Spinner} from 'reactstrap';

import {useCheckLink} from 'hooks/useCheckLink';

function AppLoader({location}) {
  const employer = location.search.split('=')[1];
  const {redirection} = useCheckLink(employer);
  if (redirection === 'signup') {
    return <Redirect to="/auth/signup" />;
  }
  if (redirection === '404') {
    return <Redirect to="/auth/signin" />;
  }
  return (
    <div className="app-loader">
      <Spinner color="primary" />
    </div>
  );
}

export default AppLoader;
