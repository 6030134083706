//router
import {push} from 'connected-react-router';

// error handler
import reportError from 'lib/errorHandler';

// import Api
import {Api} from 'api';

import {
  SIGNUP_REQUEST,
  SIGNUP_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SET_USER_EMAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
} from './actionsTypes';

import {SET_CURRENT_USER} from 'actionsTypes';

// some endpoints
import {signupUrl, signinUrl, forgotUrl, resetPassUrl} from './api/endpoints';

export const signupRequest = () => ({
  type: SIGNUP_REQUEST,
});

export const setUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const signupFailure = (payload) => ({
  type: SIGNUP_FAILURE,
  payload,
});

export const signup = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(signupRequest());
      await Api().post(signupUrl(), payload);
      dispatch(setUser(null));
      dispatch(setUserEmail(payload.email));
      dispatch(push('/auth/success'));
    } catch (error) {
      dispatch(
        signupFailure(
          error.message.includes('déjà pris')
            ? 'E-mail déjà utilisé'
            : error.message,
        ),
      );
      reportError('signup error', error);
    }
  };
};

export const signinRequest = () => ({
  type: SIGNIN_REQUEST,
});

export const signinFailure = (payload) => ({
  type: SIGNIN_FAILED,
  payload,
});

export const signin = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(signinRequest());
      const data = await Api().post(signinUrl(), payload);
      dispatch(setUser(data));
      dispatch(push('/'));
    } catch (error) {
      dispatch(signinFailure(error?.message || 'request failed'));
      reportError('signin error', error);
    }
  };
};

export const setUserEmail = (payload) => ({
  type: SET_USER_EMAIL,
  payload,
});

export const resetPasswordRequest = () => ({
  type: RESET_PASSWORD_REQUEST,
});

export const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailure = (payload) => ({
  type: RESET_PASSWORD_FAILED,
  payload,
});

export const resetPassword = (user) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordRequest());
      const data = await Api().get(resetPassUrl(user));
      dispatch(resetPasswordSuccess(data));
    } catch (error) {
      dispatch(resetPasswordFailure(error.message));
      reportError('resend error', error);
    }
  };
};

export const forgotPassRequest = () => ({
  type: FORGOT_PASSWORD_REQUEST,
});

export const forgotPassSuccess = (payload) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPassFailure = (payload) => ({
  type: FORGOT_PASSWORD_FAILED,
  payload,
});

export const forgotPass = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(forgotPassRequest());
      await Api().post(forgotUrl(), payload);
      dispatch(forgotPassSuccess());
      dispatch(setUserEmail(payload.username));
      dispatch(push('/auth/forgotSuccess'));
    } catch (error) {
      dispatch(forgotPassFailure(error.message));
      reportError('forgot pass error', error);
    }
  };
};
