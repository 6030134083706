import React from 'react';
import {any, bool, oneOf} from 'prop-types';
import classNames from 'classnames';

import 'assets/css/buttonTag.css';

const ButtonTag = (props) => {
  return (
    <div
      className={classNames('button-tag', {
        active: props.active,
        lg: props.size === 'lg',
        sm: props.size === 'sm',
        xs: props.size === 'xs',
        primary: props.primary,
        rounded: props.rounded,
      })}
      tag="button"
      onClick={props.onClick}>
      <span
        className={classNames('button-tag-label', {
          active: props.active,
        })}>
        {props.label}
      </span>
    </div>
  );
};

ButtonTag.defaultProps = {
  size: 'sm',
};

ButtonTag.propTypes = {
  label: any,
  active: bool,
  size: oneOf(['lg', 'sm', 'xs']).isRequired,
};

export default ButtonTag;
