import React, {useState, useEffect} from 'react';

// reactstrap components

import {useCheckAuth} from 'hooks/useCheckAuth';

// core components
import TopNavbar from 'components/Navbars/TopNavbar';
import CreationStepper from './creationStepper';

import {steps} from './creationConfig';

import './devis-styles.scss';

function DevisView(props) {
  const {location} = props;
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [displayModal, setDisplayModal] = useState(false);

  useCheckAuth();

  useEffect(() => {
    const step = steps.find((one) => one.hash === location.hash);
    setActiveStep(step || steps[0]);
  }, [location.hash]);

  const onCancelProcess = () => {
    setDisplayModal(true);
  };

  return (
    <div className="app-container">
      <TopNavbar isConnected={props.currentUser !== null} />
      <div className="app-content">
        <CreationStepper activeStep={activeStep.index} />
        <div>
          <activeStep.component {...props} onCancel={onCancelProcess} />
        </div>
      </div>
    </div>
  );
}

export default DevisView;
