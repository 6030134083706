import {setCurrentUser} from 'actions';

import {Api} from 'api';

import {
  CLEAR_CREATION_FORM,
  UPDATE_USERDATA_FORM,
  UPDATE_VEHICULE_DATA_FORM,
  SET_CREATED_DEVIS,
  SAVE_DEFAULT_ENABLED_CODES,
  SET_INTERDEPENDANCES,
  UPDATE_GARANTIES_FORM,
  CLEAR_STEP3_FORM,
  SET_WITH_G11,
} from './actionsTypes';

export const clearCreationForm = () => ({
  type: CLEAR_CREATION_FORM,
});

export const updateUserForm = (payload) => ({
  type: UPDATE_USERDATA_FORM,
  payload,
});

export const updateVehiculeForm = (payload) => ({
  type: UPDATE_VEHICULE_DATA_FORM,
  payload,
});

export const setCreatedDevis = (payload) => ({
  type: SET_CREATED_DEVIS,
  payload,
});

export const setDefaultGaranties = (payload) => ({
  type: SAVE_DEFAULT_ENABLED_CODES,
  payload,
});

export const setInterdeps = (payload) => ({
  type: SET_INTERDEPENDANCES,
  payload,
});

export const updateGarantiesForm = (payload) => ({
  type: UPDATE_GARANTIES_FORM,
  payload,
});

export const clearStepForm = () => ({
  type: CLEAR_STEP3_FORM,
});

export const updateUserProfile = (payload) => {
  return async (dispatch) => {
    try {
      const data = await Api().post('/me?$expand=employer', payload);
      dispatch(setCurrentUser(data));
    } catch {}
  };
};

export const setWithG11 = (payload) => ({
  type: SET_WITH_G11,
  payload,
});
