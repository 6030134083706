import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {uploadFiles} from 'actions';
import {fichiersStateSelect} from '../selector';
import {updateFilesForm} from '../actions';

export function useUploadDeclarationFiles() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const {files: savedfiles} = useSelector(fichiersStateSelect);

  function uploadFile(files) {
    const requests = [];

    files.forEach((f) => {
      let uploadPayload = new FormData();
      uploadPayload.append('file', f.file);
      requests.push(uploadFiles(uploadPayload));
    });

    const promise = new Promise((resolve, reject) => {
      Promise.all(requests)
        .then((dataP) => {
          const uploadConfig = files.map((pck, i) => {
            return {
              name: pck.fileName,
              id: dataP[i]?.file,
            };
          });
          resolve(uploadConfig);
        })
        .catch((err) => reject(err));
    });

    return promise;
  }

  async function sendFiles({payload, docType, docs}, cb) {
    try {
      setFetching(true);
      setError(null);

      let uploadPayload = await uploadFile(
        Object.keys(payload).map((one) => ({
          file: payload[one],
          fileName: one,
        })),
      );

      let uploadDocType = await uploadFile(
        docs.map((one, i) => ({
          file: one.file,
          fileName: `${docType}_${i}`,
        })),
      );

      let files = {
        [`${docType}`]: uploadDocType.map((f) => f.id),
      };

      uploadPayload.forEach((f) => {
        files = {
          ...(savedfiles && {...savedfiles}),
          ...files,
          [`${f.name}`]: f.id,
        };
      });

      dispatch(updateFilesForm(files));

      if (cb) {
        cb();
      }
    } catch (err) {
      setError(err);
    } finally {
      setFetching(false);
    }
  }

  return [{fetching, error}, sendFiles];
}
