import React, {useState, useEffect} from 'react';
import {Spinner, Row, Col} from 'reactstrap';
import {omit} from 'lodash';
import numeral from 'lib/numeral';

import ButtonTag from 'components/ButtonTag';
import DropDownInput from 'components/DropDownInput';
import NoticeModal from 'components/NoticeModal';
import VenaleModal from 'components/VenaleModal';
import AppInput from 'components/AppInput';
import StepFooter from '../../stepFooter';
import PacksList from './packs/packsList';
import CarteConfig from './carte/carteConfig';

import {useFetchConvention} from '../../hooks/useFetchConvention';
import {useCreateDevis} from 'hooks/useCreateDevis';

export default function Garanties(props) {
  const {currentUser, vehicule, garanties} = props;
  const {defaultEnabled, interdeps} = props.garanties;
  const [configType, setType] = useState('');
  const {fetching, data} = useFetchConvention(
    currentUser?.employer?.convention,
    setType,
  );
  const [selectedPack, setSelectedPack] = useState(garanties.pack || null);
  const [carteData, setCarteData] = useState(null);
  const [assistance, setAssistance] = useState(garanties.assistance || null);
  const [displayNotice, setDisplayNotice] = useState(false);
  const [displayVenalExp, setVenalExpl] = useState(false);
  const [displayComplement, setComplement] = useState(
    vehicule?.vn || vehicule?.vv || vehicule?.vdg,
  );
  const [state, setState] = useState({
    vn: vehicule?.vn || '',
    vv: vehicule?.vv || '',
    vdg: vehicule?.vdg || '',
  });

  const [g11Selected, setG11Selected] = useState(false);

  const [formError, setFormError] = useState(false);

  const [createInfos, createDevis] = useCreateDevis();

  const checkForG11 = (p) => {
    const {packsWithG11} = garanties;
    setG11Selected(packsWithG11[p] || false);
  };

  const onSubmit = () => {
    if (
      displayComplement &&
      (!state.vn || !state.vv || (g11Selected && !state.vdg))
    ) {
      setFormError(true);
    } else {
      const {userData, vehicule} = props;
      const payload = {
        type: configType,
        ...(configType === 'pack' && {pack: selectedPack}),
        ...(configType === 'carte' && {carte: data.carte}),
        ...(configType === 'carte' && {
          carteData: {...(carteData && carteData), ...defaultEnabled},
        }),
        interested: userData.interested,
        interestedData: {
          name: {
            first: userData.firstname,
            last: userData.lastname,
          },
          email: userData.email,
          phone: userData.phone,
          city: userData.city.value,
          ...(userData.relationship && {
            relationship: userData.relationship.value,
          }),
        },
        vehiculeData: {
          marque: vehicule.marque,
          model: vehicule.model,
          motorisation: vehicule.motorisation.value,
          dateEffet: vehicule.dateEffet,
          dateCirculation: vehicule.dateCirculation,
          pf: vehicule.puissance.value,
          ...(vehicule.matricule1 && {
            immatriculation: vehicule.isNew
              ? `${vehicule.matricule1}-WW`
              : `${vehicule.matricule1}-${vehicule.matricule2?.value}-${vehicule.matricule3}`,
          }),
          vv: parseFloat(state.vv.replace(/ /g, '')) || undefined,
          vn: parseFloat(state.vn.replace(/ /g, '')) || undefined,
          vdg: g11Selected
            ? parseFloat(state.vdg.replace(/ /g, '')) || undefined
            : parseFloat(state.vdg.replace(/ /g, '')) || 1,
        },
        assistance:
          assistance?.value === '-'
            ? null
            : omit(assistance, ['label', 'value']),
        assistanceData:
          assistance?.value === '-'
            ? null
            : omit(assistance, ['label', 'value']),
      };

      props.actions.updateVehiculeForm({
        vn: state.vn,
        vv: state.vv,
        vdg: state.vdg,
      });

      props.actions.updateGarantiesForm({
        assistance,
        pack: selectedPack,
        configType,
      });

      createDevis(payload, '/devis#recap');
    }
  };

  useEffect(() => {
    const shouldNotice = [
      'Invalid contraintes',
      'Nous vous prions de bien vouloir saisir les valeurs des informations manquantes',
      'Nous vous prions de bien vouloir saisir les valeurs des informations nécessaires',
      'Nous vous prions de bien vouloir saisir les valeurs suivants (VV, VN et VDG)',
      'Merci de saisir les valeurs des informations nécessaires',
    ];
    if (createInfos.error && shouldNotice.includes(createInfos.error.message)) {
      setDisplayNotice(true);
      setComplement(true);
    }
  }, [createInfos.error]);

  console.log('configType', configType);

  return (
    <div className="step-container">
      <h3 className="page-title-text">Mon Assurance</h3>
      {fetching && (
        <div className="packs-view">
          <Spinner size="sm" />
        </div>
      )}
      {data.hasPacks && data.hasCarte && (
        <div>
          <span className="step-field"> Choisissez le type </span>
          <div className="btn-grp-h">
            <ButtonTag
              onClick={() => {
                setType('pack');
                props.actions.setDefaultGaranties(null);
              }}
              label="Packs"
              active={configType === 'pack'}
            />
            <ButtonTag
              onClick={() => setType('carte')}
              label="À la carte"
              active={configType === 'carte'}
            />
          </div>
        </div>
      )}
      <div className="step-form">
        {data.hasPacks && configType === 'pack' && (
          <PacksList
            packs={data.packs}
            selected={selectedPack}
            onSelectPack={(p) => {
              setSelectedPack(p);
              setComplement(false);
              checkForG11(p);
            }}
          />
        )}
        {data.hasCarte && configType === 'carte' && (
          <CarteConfig
            carte={data.carte}
            onUpdateCarte={(cData) => {
              console.log('cData', cData);
              let newData = {};
              Object.keys(cData).forEach((garan) => {
                const currentData = cData[garan];
                newData = {
                  ...newData,
                  [`${garan}`]: {
                    isEnabled:
                      currentData.selected ||
                      defaultEnabled?.[garan] !== undefined,
                    // put id of the selected formule
                    ...(currentData.formule && {
                      formule: currentData.formule,
                      formuleData: currentData.formuleData,
                    }),
                    // put id of the first formule if the garantie is default enabled
                    // or enable by interdependance and has no formule selected
                    ...((currentData.selected ||
                      defaultEnabled?.[garan] !== undefined) &&
                      !currentData.formule &&
                      currentData.formules[0] && {
                        formule: currentData.formules[0]._id,
                        formuleData: omit(currentData.formules[0], [
                          '_id',
                          'id',
                          '__v',
                        ]),
                      }),
                    garantie: currentData.garantie,
                  },
                };
              });
              setCarteData(newData);
              setG11Selected(
                cData['g11'].selected || defaultEnabled?.['g11'] !== undefined,
              );
            }}
            interdeps={interdeps}
          />
        )}
      </div>

      {displayComplement && (
        <div className="step-form">
          <h3 className="page-title-text">Informations supplémentaires</h3>
          <Row>
            <Col xs="12" sm="4">
              <AppInput
                label="Valeur vénale (en DH)"
                name="valeur-venale"
                value={state.vv ? numeral(state.vv).format() : ''}
                onChange={(t) => {
                  console.log(t);
                  setState({...state, vv: t});
                }}
                hasError={formError && !state.vv}
                modal
                modalTitle={'Valeur vénale'}
                modalText={''}
                openNotice={() => setVenalExpl(true)}
              />
            </Col>
            <Col xs="12" sm="4">
              <AppInput
                name="valeur-neuve"
                label="Valeur neuve (en DH)"
                value={state.vn ? numeral(state.vn).format() : ''}
                onChange={(t) => setState({...state, vn: t})}
                hasError={formError && !state.vn}
                popover
                popoverTitle={'Valeur neuve'}
                popoverText={
                  <div style={{display: 'block'}}>
                    <span>
                       La valeur à neuf est le prix d’achat du véhicule,
                      déduction faite des frais d’immatriculation. Elle intègre
                      la taxe de luxe éventuelle ainsi que la TVA.
                    </span>
                  </div>
                }
              />
            </Col>
          </Row>
          {g11Selected && (
            <Row>
              <Col xs="12" sm="4">
                <AppInput
                  label="Valeur des glaces (en DH)"
                  value={state.vdg ? numeral(state.vdg).format() : ''}
                  onChange={(t) => setState({...state, vdg: t})}
                  hasError={formError && g11Selected && !state.vdg}
                />
              </Col>
            </Row>
          )}
        </div>
      )}

      {data.assistances && data.assistances.length > 0 ? (
        <div className="step-form assistance-view">
          <h3 className="page-title-text">
            Choisissez la formule d'assistance
          </h3>
          <DropDownInput
            selected={assistance}
            options={[
              {
                label: "Je ne veux pas d'assistance",
                value: '-',
              },
            ].concat(
              data.assistances.map((one) => ({
                label: one.titre + '  ' + one.prix + 'DH',
                value: one._id,
                ...one,
              })),
            )}
            setSelected={(value) => {
              setAssistance(value);
            }}
          />
        </div>
      ) : null}

      {displayNotice && (
        <NoticeModal
          display={displayNotice}
          onCancel={() => setDisplayNotice(false)}
          validations={createInfos.error?.validations}
          message={createInfos.error?.message}
          required={createInfos.error?.required}
        />
      )}

      {displayVenalExp && (
        <VenaleModal
          display={displayVenalExp}
          onCancel={() => setVenalExpl(false)}
        />
      )}

      <StepFooter
        onPressNext={() => onSubmit()}
        next="/devis#recap"
        nextDisabled={!configType || (configType === 'pack' && !selectedPack)}
        fetching={createInfos.fetching}
        history={props.history}
        onCancel={props.onCancel}
      />
    </div>
  );
}
