import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../../actions';

import {currentUserStateSelect, fichiersStateSelect} from '../../selector';

import Recap from './component';

const RecapContainer = (props) => {
  const currentUser = useSelector(currentUserStateSelect);
  const fichiers = useSelector(fichiersStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  return (
    <Recap
      devis={fichiers.devis}
      currentUser={currentUser}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default RecapContainer;
