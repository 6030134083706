import React, {useState} from 'react';

import {Col, Row, FormGroup, Label} from 'reactstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import classNames from 'classnames';
import moment from 'lib/moment';
import numeral from 'lib/numeral';
import {POINTS_CHOCS, SINISTER_CAUSES, MATRICULE_LETTERS} from 'config/app';
import inputsValidation from 'lib/inputsValidation';

import AppInput from 'components/AppInput';
import StepFooter from '../../stepFooter';

export default function SinisterInfos(props) {
  const {infos} = props;
  const [isNew, setIsNew] = useState(false);
  const [formError, setFormError] = useState(false);
  const [state, setState] = useState({
    matricule1: infos?.matricule1 || '',
    matricule2: infos?.matricule2 || '',
    matricule3: infos?.matricule3 || '',
    cnie: infos?.cnie || '',
    phone: infos?.phone || '',
    dateEffet: infos?.dateEffet || null,
    timeEffet: infos?.timeEffet || null,
    accident: infos?.accident || null,
  });

  const [collision, setCollision] = useState([]);

  const today = moment(); //.subtract(0, 'days');
  const validEffetDate = function (current) {
    return current.isBefore(today);
  };

  const invalidForm =
    !state.attestation ||
    !state.matricule1 ||
    (!isNew && (!state.matricule2 || !state.matricule3)) ||
    !state.phone ||
    !state.dateEffet ||
    !state.accident;

  const collisionSelected = (sel) => collision.includes(sel);

  const removeToCollision = (sel) => {
    const i = collision.lastIndexOf(sel);
    collision.splice(i, 1);
    setCollision([...collision]);
  };

  const addToCollision = (sel) => {
    setCollision([...collision, ...[sel]]);
  };

  const onSubmit = () => {
    const cinError = !inputsValidation('cin', state.cnie);
    const phoneError = !inputsValidation('phone', state.phone);
    if (cinError || phoneError) {
      setState({...state, cinError, phoneError});
    } else {
      props.actions.updateUserForm({
        ...state,
        collision,
        isNew,
        points: POINTS_CHOCS.map((one) => ({
          name: one.value,
          enabled: collision.includes(one.value),
        })),
      });
      props.history.push('/declaration#fichiers');
    }
  };

  return (
    <div className="step-container">
      <h3 className="page-title-text">Déclaration d'un sinistre</h3>

      <div className="step-form">
        <Row>
          <Col xs="12" sm="4">
            <div className="step-chk-btn" onClick={() => setIsNew(!isNew)}>
              <span>Immatriculation</span>
              <span className="step-field ml-4">
                <img
                  alt="v"
                  src={
                    isNew
                      ? require('assets/img/checked.png')
                      : require('assets/img/noChecked.png')
                  }
                  className="step-checkmark"
                />
                en ww
              </span>
            </div>
            <div className="matricule-container">
              {isNew ? (
                <>
                  <div className="space-right text-center">
                    <span> WW </span>
                  </div>
                  <AppInput
                    label=""
                    value={state.matricule1}
                    onChange={(t) => setState({...state, matricule1: t})}
                    type="number"
                    maxLength="5"
                  />
                </>
              ) : (
                <>
                  <AppInput
                    label=""
                    value={state.matricule1}
                    onChange={(t) => setState({...state, matricule1: t})}
                    type="number"
                    maxLength="5"
                  />
                  <FormGroup className="matricule-letter">
                    <Select
                      placeholder=""
                      className="react-select custom-input"
                      classNamePrefix="react-select"
                      onChange={(select) => {
                        setState({...state, matricule2: select});
                      }}
                      value={state.matricule2}
                      options={MATRICULE_LETTERS}></Select>
                  </FormGroup>
                  <AppInput
                    label=""
                    value={state.matricule3}
                    onChange={(t) => setState({...state, matricule3: t})}
                    type="number"
                    maxLength="2"
                    // className="matricule-last"
                  />
                </>
              )}
            </div>
          </Col>
          <Col xs="12" sm="4">
            <AppInput
              name="valeur-neuve"
              label="Numéro attestation"
              value={
                state.attestation ? numeral(state.attestation).format() : ''
              }
              onChange={(t) => setState({...state, attestation: t})}
              hasError={formError && !state.attestation}
              popover
              popoverText={
                <div style={{display: 'block'}}>
                  <img
                    width="150px"
                    src={require('assets/img/contrat.png')}
                    alt=">"
                  />
                </div>
              }
            />
            {/* <AppInput
              label="Numéro attestation"
              value={state.attestation}
              onChange={(t) => setState({...state, attestation: t})}
            /> */}
          </Col>
        </Row>
        <Row>
          {/* <Col xs="12" sm="4">
            <AppInput
              label="CIN"
              value={state.cnie}
              onChange={(t) =>
                setState({
                  ...state,
                  cnie: t,
                })
              }
              hasError={state.cinError}
            />
          </Col> */}
          <Col xs="12" sm="4">
            <AppInput
              label="Numéro de téléphone du contact"
              required
              value={state.phone}
              onChange={(t) =>
                setState({
                  ...state,
                  phone: t,
                })
              }
              hasError={state.phoneError}
            />
          </Col>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>
                Date de sinistre <span>*</span>{' '}
              </Label>
              <Datetime
                className="creation-date"
                inputProps={{
                  className: classNames('custom-input', {
                    error:
                      state.dateEffet && new Date(state.dateEffet) > new Date(),
                  }),
                  placeholder: '',
                }}
                value={
                  state.dateEffet ? moment(state.dateEffet).format('L') : ''
                }
                closeOnSelect
                onChange={(e) => {
                  setState({
                    ...state,
                    dateEffet: e,
                  });
                }}
                timeFormat={false}
                isValidDate={validEffetDate}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {/* <Col xs="12" sm="4">
            <FormGroup>
              <Label>Heure du sinistre</Label>
              <Datetime
                className="creation-date"
                inputProps={{
                  className: 'creation-date-input',
                  placeholder: '',
                }}
                value={
                  state.timeEffet ? moment(state.timeEffet).format('LT') : ''
                }
                closeOnSelect
                onChange={(e) => {
                  setState({
                    ...state,
                    timeEffet: e,
                  });
                }}
                dateFormat={false}
                timeFormat
              />
            </FormGroup>
          </Col> */}
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Circonstances de l'accident"
              value={state.circonstance}
              onChange={(t) =>
                setState({
                  ...state,
                  circonstance: t,
                })
              }
            />
          </Col>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>
                Type Accident <span>*</span>{' '}
              </Label>
              <Select
                className="react-select custom-input"
                classNamePrefix="react-select"
                onChange={(select) => {
                  setState({...state, accident: select});
                }}
                value={state.accident}
                options={SINISTER_CAUSES}></Select>
            </FormGroup>
          </Col>
        </Row>
        <div>
          <Label>Points de choc</Label>
          <Row>
            {POINTS_CHOCS.map((pt) => (
              <Col key={pt.value} xs="12" sm="4" className="choc-text">
                <span>
                  {collisionSelected(pt.value) ? (
                    <img
                      src={require('assets/img/square_check_full.png')}
                      alt="o"
                      className="info-check-mark"
                      role="button"
                      onClick={() => removeToCollision(pt.value)}
                    />
                  ) : (
                    <img
                      src={require('assets/img/square_check.png')}
                      alt="o"
                      className="info-check-mark"
                      role="button"
                      onClick={() => addToCollision(pt.value)}
                    />
                  )}
                  {pt.label}
                </span>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <StepFooter
        previous={false}
        onPressNext={() => onSubmit()}
        next="/declaration#fichiers"
        nextDisabled={invalidForm}
        history={props.history}
        onCancel={props.onCancel}
      />
    </div>
  );
}
