import React from 'react';
import {useDispatch} from 'react-redux';

import StepFooter from '../../stepFooter';

// reactstrap components

// core components
import TopNavbar from 'components/Navbars/TopNavbar';
import CreationStepper from '../../creationStepper';

import {clearDeclarationForm} from '../../actions';

import '../../declaration-styles.scss';

function DeclarationSuccess(props) {
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(clearDeclarationForm());
    props.history.push('/');
  };

  return (
    <div className="app-container">
      <TopNavbar isConnected={props.currentUser !== null} />
      <div className="app-content">
        <CreationStepper activeStep={3} />
        <div>
          <div className="step-container text-center">
            <h3 className="page-title-text no-bottom">
              Nous avons bien reçu votre déclaration de sinistre.
              <br />
              Un gestionnaire AGMA prendra contact avec vous dans les 24 heures.
            </h3>
          </div>
          <div className="text-center"></div>
          <div className="text-center bottom-10"></div>
          <div className="text-center">
            <img
              alt="=>"
              src={require('assets/img/devisSuccess.jpg')}
              className="devis-success"
            />
          </div>
          <StepFooter
            previousDisplay={false}
            onPressNext={() => onSubmit()}
            next="/"
            nextText="Terminer"
            //nextDisabled={invalidForm}
            //fetching={values.fetching}
            history={props.history}
            onCancel={props.onCancel}
          />
        </div>
      </div>
    </div>
  );
}

export default DeclarationSuccess;
