import React, {useState} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import classNames from 'classnames';

const DDInput = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    console.log('toggling');
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        aria-expanded={false}
        aria-haspopup={true}
        data-toggle="dropdown"
        data-stripe={props.dataStripe}
        href="#pablo"
        id="dropdownMenuLink"
        onClick={(e) => e.preventDefault()}
        className="btn btn-input">
        <span>
          {props.selected?.label || props.placeholder || 'Choisir'}
          {props.selected?.description !== undefined && (
            <>
              <br />
              <span>{props.selected?.description}</span>
            </>
          )}
        </span>
        {props.arrowDisplay && (
          <img
            src={require('assets/img/dropdown.png')}
            className="dropdown-arrow"
            alt=">"
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="dropdown-options">
        {props.options.map((option) => (
          <DropdownItem
            key={option.value}
            onClick={(e) => props.setSelected(option)}>
            <span>
              <span
                className={classNames('dropdown-label', {
                  bold: props.labelBold,
                })}>
                {option.label}
              </span>
              {option.labelComplement !== undefined && (
                <span>{option.labelComplement} </span>
              )}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

DDInput.defaultProps = {
  arrowDisplay: true,
};

export default DDInput;
