import React from 'react';
import {useSelector} from 'react-redux';

// reactstrap components
import {Button} from 'reactstrap';
// core components
import AuthNavbar from 'components/Navbars/AuthNavbar';

import {authStateSelect} from '../../selector';

export default function PaymentView(props) {
  const {username} = useSelector(authStateSelect);

  const goToHome = () => {
    props.history.push('/auth/signin');
  };

  return (
    <div className="app-container">
      <AuthNavbar register />
      <div className="app-content">
        <div className="title-view">
          <span className="creation-title">Félicitations</span>
        </div>
        <div className="creation-form">
          <p className="text-black">
            {`Nous vous avons envoyé un e-mail à l'adresse ${username} pour valider votre compte.`}
          </p>
          <p>Pensez à vérifier les mails indésirables </p>
        </div>
        <div className="creation-footer">
          <Button
            className="btn-round"
            color="secondary"
            onClick={goToHome}
            size="lg">
            Terminer
          </Button>
        </div>
      </div>
    </div>
  );
}
