import CONFIG from 'config/apiConfig';
import {setSession} from 'lib/storage';

import abortController from 'lib/abortController';

// public urls
import {signupUrl} from 'modules/Auth/api/endpoints';

const {API_BASE_URL, API_VERSION, REQUEST_TIMER} = CONFIG;
const baseURL = `${API_BASE_URL}${API_VERSION}`;

const publicUrls = [`${baseURL}${signupUrl()}`, `${baseURL}/cgus/application`];

const Api = () => {
  const core = (url, method, data, isForm) => {
    const promise = new Promise((resolve, reject) => {
      const controller = abortController.getInstance();

      // configure request body
      const body = isForm ? data : JSON.stringify(data);

      // configure request header
      const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const formHeaders = {};

      const headers = isForm ? formHeaders : header;

      // put request configs all together
      let req = {
        method,
        headers,
        body,
        credentials: 'include',
        signal: controller.signal,
      };

      // send request
      fetch(url, req)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            resolve(response);
          } else {
            response
              .json()
              .then((result) => {
                reject(result);
              })
              .catch(() => reject(null));
          }
          clearTimeout(timeout);
        })
        .catch((err) => {
          if (err.name === 'AbortError') {
            reject(new Error('Response timed out'));
          } else {
            reject(err);
          }
          clearTimeout(timeout);
        });

      const timeout = setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMER);
    });

    return promise;
  };

  return {
    get: (path, payload) => {
      const promise = new Promise((resolve, reject) => {
        const url = `${baseURL}${path}`;
        core(url, 'GET', payload)
          .then((reponse) => reponse.json())
          .then((data) => {
            resolve(data);
          })
          .catch((error) => reject(error));
      });
      return promise;
    },
    post: (path, payload, isForm) => {
      const promise = new Promise((resolve, reject) => {
        const url = `${baseURL}${path}`;
        core(url, 'POST', payload, isForm)
          .then((reponse) => reponse.json())
          .then((data) => {
            if (url === publicUrls[0]) {
              setSession(data.id);
            }
            resolve(data);
          })
          .catch((error) => reject(error));
      });
      return promise;
    },
    put: (path, payload) => {
      const promise = new Promise((resolve, reject) => {
        const url = `${baseURL}${path}`;
        core(url, 'PUT', payload)
          .then((reponse) => reponse.json())
          .then((data) => {
            resolve(data);
          })
          .catch((error) => reject(error));
      });
      return promise;
    },
    delete: (path) => {
      const promise = new Promise((resolve, reject) => {
        const url = `${baseURL}${path}`;
        core(url, 'DELETE')
          .then((reponse) => reponse.json())
          .then((data) => {
            resolve(data);
          })
          .catch((error) => reject(error));
      });
      return promise;
    },
    cancelRequest: () => {
      const oldcontroller = abortController.getInstance();
      oldcontroller.abort();
    },
  };
};

export default Api;
