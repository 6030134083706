import {useState, useEffect} from 'react';
import {pick, omit, isNil} from 'lodash';
import {useDispatch} from 'react-redux';

import {Api} from 'api';
import {setWithG11} from '../actions';

export function useFetchPack(pack) {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const data = await Api().get(`/packs/${pack}`);
        const infos = pick(data, [
          'contraintes',
          '_id',
          'id',
          'name',
          'imageUrl',
          '__v',
        ]);
        const garanties = Object.values(
          omit(data, ['contraintes', '_id', 'id', 'name', 'imageUrl', '__v']),
        ).filter((one) => one.isEnabled);
        setData({infos, garanties});
        const withG11 = garanties.find((one) => one.code === 'g11');
        if (!isNil(withG11)) {
          dispatch(setWithG11({[`${pack}`]: true}));
        }
      } catch {
        // maybe display an error message
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dispatch, pack]);

  return {fetching, data};
}
