import React from 'react';
import moment from 'lib/moment';
import {Capitalize} from 'lib/capitalize';
import {downloadFile} from 'lib/helpers';
import downloadApi from 'api/downloadApi';

import StatusTag from 'components/StatusTag';

const statusText = {
  IN_PROGRESS: 'Devis en attente de confirmation',
  NOT_CONFIRMED: 'Devis en attente de confirmation',
  CONFIRMED_WITHOUT_PJ: 'Devis confirmé, manque PJ',
  CONFIRMED_WITH_PJ: 'Confirmé',
};

export const columns = [
  {
    title: 'N°',
    dataIndex: 'code',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">{info.code}</span>
      </div>
    ),
  },
  {
    title: 'Nom',
    dataIndex: 'interestedData',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">
          {Capitalize(info.interestedData.name.last)}
        </span>
      </div>
    ),
  },
  {
    title: 'Prénom',
    dataIndex: 'interestedData',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">
          {Capitalize(info.interestedData.name.first)}
        </span>
      </div>
    ),
  },
  {
    title: 'Date de demande',
    dataIndex: 'created_at',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">{moment(info.createdAt).format('L')}</span>
      </div>
    ),
  },
  {
    title: 'Statut',
    dataIndex: 'statut',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <StatusTag
          label={statusText[info.statut]}
          status={`tag-${info.statut?.toLowerCase()}`}
        />
      </div>
    ),
  },
  {
    title: '',
    dataIndex: '_id',
    renderItem: (info) => {
      return (
        <span
          onClick={async () => {
            try {
              const pdfUrl = await downloadApi(`/requests/${info._id}/report`);
              downloadFile(pdfUrl);
            } catch {}
          }}
          className="download-text">
          Télécharger le devis
        </span>
      );
    },
  },
];
