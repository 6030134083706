import React, {useState} from 'react';

import {Col, Row, FormGroup, Label} from 'reactstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import classNames from 'classnames';
import moment from 'lib/moment';

import {MOTORS, PUISSANCE_FISCAL /*, MATRICULE_LETTERS*/} from 'config/app';

// import AppInput from 'components/AppInput';

import StepFooter from '../../stepFooter';

export default function Vehicule(props) {
  const {vehicule} = props;
  const [isNew /* setIsNew*/] = useState(false);
  const [state, setState] = useState({
    ...vehicule,
    dateEffet: vehicule.dateEffet || null,
    dateCirculation: vehicule.dateCirculation || null,
  });

  const today = moment(); //.subtract(0, 'days');
  const validEffetDate = function (current) {
    return current.isAfter(today);
  };

  const invalidForm =
    !state.motorisation ||
    !state.puissance ||
    !state.dateEffet ||
    moment(state.dateEffet).format('L') === 'Invalid date' ||
    new Date(state.dateEffet) < new Date() ||
    !state.dateCirculation ||
    moment(state.dateCirculation).format('L') === 'Invalid date'; //

  console.log('moment(state.dateEffet)', moment(state.dateEffet).format('L'));

  const onSubmit = () => {
    props.actions.updateVehiculeForm({
      ...state,
      dateEffet: `${moment(state.dateEffet).format('YYYY-MM-DD')}T00:00`,
      dateCirculation: `${moment(state.dateCirculation).format(
        'YYYY-MM-DD',
      )}T00:00`,
      isNew,
    });
    props.history.push('/devis#garanties');
  };

  return (
    <div className="step-container">
      <div className="page-subtitle">
        <h3 className="page-title-text">Mon véhicule</h3>
        <span>(l’ensemble des champs sont obligatoires)</span>
      </div>
      <div className="step-form">
        {/*<Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Marque (optionnel)"
              value={state.marque}
              onChange={(t) => setState({...state, marque: t})}
            />
          </Col>
          <Col xs="12" sm="4">
            <AppInput
              label="Modèle (optionnel)"
              value={state.model}
              onChange={(t) => setState({...state, model: t})}
            />
          </Col>
        </Row>*/}
        <Row>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>
                Motorisation <span>*</span>{' '}
              </Label>
              <Select
                className="react-select custom-input"
                classNamePrefix="react-select"
                onChange={(select) => {
                  setState({...state, motorisation: select});
                }}
                value={state.motorisation}
                options={MOTORS}></Select>
            </FormGroup>
          </Col>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>
                Puissance fiscale <span>*</span>{' '}
              </Label>
              <Select
                className="react-select custom-input"
                classNamePrefix="react-select"
                onChange={(select) => {
                  setState({...state, puissance: select});
                }}
                value={state.puissance}
                options={PUISSANCE_FISCAL}></Select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>
                Date d'effet <span>*</span>{' '}
              </Label>
              <Datetime
                className="creation-date"
                inputProps={{
                  className: classNames('custom-input', {
                    // matriculeLast: props.className === 'matricule-last',
                    error:
                      state.dateEffet && new Date(state.dateEffet) < new Date(),
                  }),
                  placeholder: '',
                }}
                value={
                  state.dateEffet ? moment(state.dateEffet).format('L') : ''
                }
                closeOnSelect
                onChange={(e) => {
                  setState({
                    ...state,
                    dateEffet: e,
                  });
                }}
                timeFormat={false}
                isValidDate={validEffetDate}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>
                Date de mise en circulation <span>*</span>{' '}
              </Label>
              <Datetime
                className="creation-date"
                inputProps={{
                  className: 'creation-date-input',
                  placeholder: '',
                }}
                value={
                  state.dateCirculation
                    ? moment(state.dateCirculation).format('L')
                    : ''
                }
                closeOnSelect
                onChange={(e) => {
                  setState({
                    ...state,
                    dateCirculation: e,
                  });
                }}
                timeFormat={false}
              />
            </FormGroup>
          </Col>
        </Row>
        {/*<div>
          <span className="step-field-title">
            Immatriculation (optionnel) 
          </span>
        </div>
        <div className="step-chk-btn" onClick={() => setIsNew(!isNew)}>
          <span className="step-field">
            <img
              alt="v"
              src={
                isNew
                  ? require('assets/img/checked.png')
                  : require('assets/img/noChecked.png')
              }
              className="step-checkmark"
            />
            Immatriculation en ww
          </span>
        </div>
        <div className="matricule-container">
          {isNew ? (
            <>
              <div className="space-right text-center">
                <span> WW </span>
              </div>
              <AppInput
                label=""
                value={state.matricule1}
                onChange={(t) => setState({...state, matricule1: t})}
                type="number"
                maxLength="5"
              />
            </>
          ) : (
            <>
              <AppInput
                label=""
                value={state.matricule1}
                onChange={(t) => setState({...state, matricule1: t})}
                type="number"
                maxLength="5"
              />
              <FormGroup className="matricule-letter">
                <Select
                  placeholder=""
                  className="react-select custom-input"
                  classNamePrefix="react-select"
                  onChange={(select) => {
                    setState({...state, matricule2: select});
                  }}
                  value={state.matricule2}
                  options={MATRICULE_LETTERS}></Select>
              </FormGroup>
              <AppInput
                label=""
                value={state.matricule3}
                onChange={(t) => setState({...state, matricule3: t})}
                type="number"
                maxLength="2"
                // className="matricule-last"
              />
            </>
          )}
        </div>*/}
        {/*<Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Valeur vénale (optionnel)"
              value={state.vv}
              onChange={(t) => setState({...state, vv: t})}
            />
          </Col>
          <Col xs="12" sm="4">
            <AppInput
              label="valeur neuve (optionnel)"
              value={state.vn}
              onChange={(t) => setState({...state, vn: t})}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <AppInput
              label="Valeur des glaces (optionnel)"
              value={state.vdg}
              onChange={(t) => setState({...state, vdg: t})}
            />
          </Col>
        </Row>*/}
      </div>
      <StepFooter
        onPressNext={() => onSubmit()}
        next="/devis#garanties"
        nextDisabled={invalidForm}
        //fetching={values.fetching}
        history={props.history}
        onCancel={props.onCancel}
      />
    </div>
  );
}
