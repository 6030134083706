export function signupUrl() {
  return '/auth/users/signup';
}

export function signinUrl() {
  return `/auth/users/signin?$expand=employer`;
}

export function forgotUrl() {
  return `/auth/users/forgot`;
}

export function resetPassUrl() {
  return '/auth/users/password';
}
