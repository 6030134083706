import {createSelector} from 'reselect';

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser,
);

export const listStateSelect = createSelector(
  (state) => state.sinistres,
  (sinistres) => sinistres.list,
);

export const detailsStateSelect = createSelector(
  (state) => state.sinistres,
  (sinistres) => sinistres.details,
);
