// import front-end config
import {
  CLEAR_DECLARATION_FORM,
  UPDATE_SINISTER_INFO_FORM,
} from '../actionsTypes';
import {LOGOUT_SUCCESS} from 'actionsTypes';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SINISTER_INFO_FORM:
      return {...state, ...action.payload};

    case CLEAR_DECLARATION_FORM:
    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
