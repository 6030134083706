// import front-end config
import {
  SIGNUP_REQUEST,
  SIGNUP_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SET_USER_EMAIL,
} from '../actionsTypes';

import {SET_CURRENT_USER, LOGOUT_SUCCESS} from 'actionsTypes';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  requestError: false,
  fetching: false,
  errorMessage: '',
  username: '',
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_EMAIL:
      return {...state, username: action.payload};

    case SET_CURRENT_USER:
      return {...state, fetching: false, requestError: false};

    case SIGNUP_REQUEST:
      return {
        ...state,
        fetching: true,
        requestError: false,
        errorMessage: '',
      };

    case SIGNUP_FAILURE:
      return {
        ...state,
        fetching: false,
        requestError: true,
        errorMessage: action.payload,
      };

    case SIGNIN_REQUEST:
      return {
        ...state,
        fetching: true,
        requestError: false,
        errorMessage: '',
      };

    case SIGNIN_FAILED:
      return {
        ...state,
        fetching: false,
        requestError: true,
        errorMessage: action.payload,
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        fetching: true,
        requestError: false,
        errorMessage: '',
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        fetching: false,
        requestError: false,
      };

    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        fetching: false,
        requestError: true,
        errorMessage: action.payload,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
