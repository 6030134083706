import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {omit} from 'lodash';
import {push} from 'connected-react-router';

import {uploadFiles} from 'actions';
import {setCurrentDemand} from 'modules/Demandes/actions';

import {Api} from 'api';

import {devisById} from 'modules/Devis/api/endpoints';

export function useUpdateDevis() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  function uploadFile(files) {
    const requests = [];

    files.forEach((f) => {
      let uploadPayload = new FormData();
      uploadPayload.append('file', f.file);
      requests.push(uploadFiles(uploadPayload));
    });

    const promise = new Promise((resolve, reject) => {
      Promise.all(requests)
        .then((dataP) => {
          const uploadConfig = files.map((pck, i) => {
            return {
              name: pck.fileName,
              id: dataP[i]?.file,
            };
          });
          resolve(uploadConfig);
        })
        .catch((err) => reject(err));
    });

    return promise;
  }

  async function updateDevis(payload, devis, edit) {
    try {
      setFetching(true);
      setError(null);
      const uploadPayload = omit(payload, 'paymentMode');
      let uploadConfig;

      uploadConfig = await uploadFile(
        Object.keys(uploadPayload).map((one) => ({
          file: payload[one],
          fileName: one,
        })),
      );

      let fileObject = {
        files: {},
      };

      uploadConfig.forEach((f) => {
        fileObject = {
          ...fileObject,
          files: {
            ...fileObject.files,
            [`${f.name}`]: f.id,
          },
          statut: 'CONFIRMED_WITH_PJ',
        };
      });

      console.log(uploadConfig, fileObject);

      const updatedPayload = {
        paymentMode: payload.paymentMode,
        ...fileObject,
      };
      const data = await Api().put(devisById(devis), updatedPayload);

      setFetching(false);
      setData(data);
      if (edit) {
        dispatch(setCurrentDemand(data));
      } else {
        dispatch(push('/success'));
      }
    } catch (err) {
      setFetching(false);
      setError(err);
    }
  }

  return [{fetching, error, data}, updateDevis];
}
