import {createSelector} from 'reselect';

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser,
);

export const infosStateSelect = createSelector(
  (state) => state.declaration,
  (declaration) => declaration.infos,
);

export const fichiersStateSelect = createSelector(
  (state) => state.declaration,
  (declaration) => declaration.fichiers,
);

export const confirmStateSelect = createSelector(
  (state) => state.declaration,
  (declaration) => declaration.confirm,
);
