import Step1 from './containers/userData';
import Step2 from './containers/vehicule';
import Step3 from './containers/garanties';
import Step4 from './containers/recap';
import Step5 from './containers/fichiers';

export const steps = [
  {
    label: 'Mes coordonnées Assuré',
    hash: '#user',
    index: 0,
    component: Step1,
  },
  {label: 'Mon véhicule', hash: '#vehicule', index: 1, component: Step2},
  {label: 'Mon Assurance', hash: '#garanties', index: 2, component: Step3},
  {label: 'Devis', hash: '#recap', index: 3, component: Step4},
  {label: 'Pièces Jointes', hash: '#fichiers', index: 4, component: Step5},
];
