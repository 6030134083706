import React, {useState} from 'react';

import {Row, Col, FormGroup, Label} from 'reactstrap';

import Avatar from '@material-ui/core/Avatar';
import Select from 'react-select';
import classNames from 'classnames';

import {DOCS_TYPES} from 'config/app';

import {useUploadDeclarationFiles} from '../../hooks/useUploadDeclarationFiles';

import StepFooter from '../../stepFooter';

export default function Fichiers(props) {
  const [cin, setCin] = useState({recto: null, verso: null});
  const [carte_grise, setCarteGrise] = useState({recto: null, verso: null});
  const [attestation_assurance, setAttestationAssurance] = useState(null);
  const [docs, setDoc] = useState([]);
  const [docType, setDocType] = useState(null);

  const [currentFile, setCurrentFile] = useState(null);
  const [hasError, setHasError] = useState(false);

  const {files} = props.fichiers;

  console.log(
    'docs',
    docs,
    !docs.length,
    !attestation_assurance,
    !docType,
    docType?.value,
  );
  const [{fetching}, sendFiles] = useUploadDeclarationFiles();

  const onSelectFile = (current) => {
    setCurrentFile(current);
    document.getElementById('file-select').click();
  };
  const invalidForm =
    docType && docType?.value === 'declarationHon'
      ? !docType || !attestation_assurance
      : // (!cin.recto && !cin.verso) ||
      // (!carte_grise.recto && !carte_grise.verso)
      docType && docType?.value === 'constatAmiable'
      ? !docType || !docs.length || !attestation_assurance
      : !docType || !docs.length || !attestation_assurance;
  // (!cin.recto && !cin.verso) ||
  // (!carte_grise.recto && !carte_grise.verso);

  const onSubmit = () => {
    const allEmpty = !docType && !docs.length && !attestation_assurance;
    // !cin.recto &&
    // !cin.verso &&
    // !carte_grise.recto &&
    // !carte_grise.verso;
    if (files?.length && allEmpty) {
      props.history.push('/declaration#confirm');
    } else {
      if (invalidForm) {
        setHasError(true);
      } else {
        const payload = {
          ...(carte_grise?.recto && {cgRecto: carte_grise.recto.file}),
          ...(carte_grise?.verso && {cgVerso: carte_grise.verso.file}),
          ...(cin?.recto && {cnieRecto: cin.recto.file}),
          ...(cin?.verso && {cnieVerso: cin.verso.file}),
          ...(attestation_assurance && {
            assurance: attestation_assurance.file,
          }),
        };
        setHasError(false);
        sendFiles({payload, docType: docType.value, docs}, () =>
          props.history.push('/declaration#confirm'),
        );
        // props.history.push('/declaration#confirm');
      }
    }
  };

  return (
    <div className="step-container">
      <h3 className="page-title-text">Déclaration d'un sinistre</h3>
      <div className="step-form">
        <Row>
          <Col xs="12" sm="4">
            <FormGroup>
              <Label>
                Type de Document <span>*</span>{' '}
              </Label>
              <Select
                className="react-select custom-input"
                classNamePrefix="react-select"
                onChange={(select) => {
                  setDocType(select);
                }}
                value={docType}
                options={DOCS_TYPES}></Select>
            </FormGroup>
          </Col>
        </Row>
        {docType && docType.value !== 'declarationHon' && (
          <div>
            <span
              className={classNames('step-field', {
                error: hasError && !docs.length,
              })}>
              <span
                className={classNames('files-name', {
                  error: hasError && !docs.length,
                })}>
                {`Téléversez ${docType.prepo} ${docType.label}`}
              </span>
            </span>
            <div className="files-container">
              {((docType.value === 'declarationHon' && docs.length < 4) ||
                (docType.value !== 'declarationHon' && docs.length < 5)) && (
                <div onClick={() => onSelectFile('doc')} className="add-file">
                  <img
                    src={require('assets/img/add-photos.png')}
                    className="add-btn"
                    alt="+"
                  />
                </div>
              )}
              <div className="files-content">
                {docs.map((dc, i) => (
                  <div className="photo-preview">
                    <Avatar variant="rounded" alt="logo" src={dc.url} />
                    <img
                      alt="x"
                      src={require('assets/img/rm-icon.png')}
                      className="pack-photo-remove"
                      onClick={() => {
                        docs.splice(i, 1);
                        setDoc([...docs]);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div>
          <span
            className={classNames('step-field', {
              error: hasError && !attestation_assurance,
            })}>
            <span
              className={classNames('files-name', {
                error: hasError && !attestation_assurance,
              })}>
              Attestation d'assurance
            </span>
          </span>
          <div className="files-container">
            <div
              onClick={() => onSelectFile('attestation_assurance')}
              className="add-file">
              <img
                src={require('assets/img/add-photos.png')}
                className="add-btn"
                alt="+"
              />
            </div>
            <div className="files-content">
              {attestation_assurance && (
                <div className="photo-preview">
                  <Avatar
                    variant="rounded"
                    alt="logo"
                    src={attestation_assurance.url}
                  />
                  <img
                    alt="x"
                    src={require('assets/img/rm-icon.png')}
                    className="pack-photo-remove"
                    onClick={() => setAttestationAssurance(null)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {docType && docType.value !== 'constatAmiable' && (
          <>
            <div>
              <span
                className={classNames('step-field', {
                  error: hasError && !carte_grise.recto && !carte_grise.verso,
                })}>
                <span
                  className={classNames('files-name', {
                    error: hasError && !carte_grise.recto && !carte_grise.verso,
                  })}>
                  Carte grise
                </span>
                (Recto / Verso)
              </span>
              <div className="files-container">
                <div
                  onClick={() => onSelectFile('carte_grise')}
                  className="add-file">
                  <img
                    src={require('assets/img/add-photos.png')}
                    className="add-btn"
                    alt="+"
                  />
                </div>
                <div className="files-content">
                  {carte_grise.recto && (
                    <div className="photo-preview">
                      <Avatar
                        variant="rounded"
                        alt="logo"
                        src={carte_grise.recto.url}
                      />
                      <img
                        alt="x"
                        src={require('assets/img/rm-icon.png')}
                        className="pack-photo-remove"
                        onClick={() =>
                          setCarteGrise({...carte_grise, recto: null})
                        }
                      />
                    </div>
                  )}
                  {carte_grise.verso && (
                    <div className="photo-preview">
                      <Avatar
                        variant="rounded"
                        alt="logo"
                        src={carte_grise.verso.url}
                      />
                      <img
                        alt="x"
                        src={require('assets/img/rm-icon.png')}
                        className="pack-photo-remove"
                        onClick={() =>
                          setCarteGrise({...carte_grise, verso: null})
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <span
                className={classNames('step-field', {
                  error: hasError && !cin.recto && !cin.verso,
                })}>
                <span
                  className={classNames('files-name', {
                    error: hasError && !cin.recto && !cin.verso,
                  })}>
                  CIN
                </span>
                (Recto / Verso)
              </span>
              <div className="files-container">
                <div onClick={() => onSelectFile('cnie')} className="add-file">
                  <img
                    src={require('assets/img/add-photos.png')}
                    className="add-btn"
                    alt="+"
                  />
                </div>
                <div className="files-content">
                  {cin.recto && (
                    <div className="photo-preview">
                      <Avatar
                        variant="rounded"
                        alt="logo"
                        src={cin.recto.url}
                      />
                      <img
                        alt="x"
                        src={require('assets/img/rm-icon.png')}
                        className="pack-photo-remove"
                        onClick={() => setCin({...cin, recto: null})}
                      />
                    </div>
                  )}
                  {cin.verso && (
                    <div className="photo-preview">
                      <Avatar
                        variant="rounded"
                        alt="logo"
                        src={cin.verso.url}
                      />
                      <img
                        alt="x"
                        src={require('assets/img/rm-icon.png')}
                        className="pack-photo-remove"
                        onClick={() => setCin({...cin, verso: null})}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )} */}
      </div>
      <input
        className="file-input-hidden"
        type="file"
        id="file-select"
        accept="image/*"
        onChange={(e) => {
          // e.preventDefault();
          const file = e.target.files[0];
          console.log('file', file);
          const url = URL.createObjectURL(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            if (currentFile === 'carte_grise') {
              setCarteGrise({
                recto:
                  carte_grise.recto === null ? {file, url} : carte_grise.recto,
                verso: carte_grise.recto === null ? null : {file, url},
              });
            }
            if (currentFile === 'attestation_assurance') {
              setAttestationAssurance({file, url});
            }
            if (currentFile === 'cnie') {
              setCin({
                recto: cin.recto === null ? {file, url} : cin.recto,
                verso: cin.recto === null ? null : {file, url},
              });
            }
            if (currentFile === 'doc') {
              setDoc([...docs, ...[{file, url}]]);
            }
          };

          reader.onerror = (e) => {
            console.log('e', e);
          };

          reader.readAsDataURL(file);
          e.target.value = null;
        }}
      />
      <StepFooter
        previous
        onPressNext={() => onSubmit()}
        next="/declaration#confirm"
        nextDisabled={files?.length ? false : invalidForm}
        fetching={fetching}
        nextText="Continuer"
        history={props.history}
        onCancel={props.onCancel}
      />
    </div>
  );
}
