export function meUrl() {
  return '/me?$expand=employer';
}

export function logoutUrl() {
  return '/auth/signout';
}

export function uploadUrl() {
  return '/files';
}
