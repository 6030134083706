import {createSelector} from 'reselect';

export const authStateSelect = createSelector(
  (state) => state.auth,
  (auth) => auth,
);

export const userStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser,
);

export const employerStateSelect = createSelector(
  (state) => state.app,
  (app) => app.employer,
);
