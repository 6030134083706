import React from 'react';
import classNames from 'classnames';
import {
  FormGroup,
  Input,
  Label,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';

export default function AppInput({
  label,
  required,
  hasError,
  name,
  placeholder,
  type,
  value,
  onChange,
  pattern,
  ...props
}) {
  return (
    <FormGroup>
      {label !== '' && (
        <Label
          id={
            props.popover ? `input-${name}-consultable` : `input-${name}-label`
          }
          className={classNames('text-black', {
            // matriculeLast: props.className === 'matricule-last',
            'label-clickable': props.modal,
          })}
          onClick={() => {
            if (props.modal) {
              props.openNotice();
            }
          }}
          for="login-email">
          {label}
          {required && <span>*</span>}
          <span className="text-regular">{props.cmplt}</span>
          {(props.popover || props.modal) && (
            <img
              alt="i"
              src={require('assets/img/info_green.png')}
              style={{
                marginLeft: '10px',
                width: '15px',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          )}
        </Label>
      )}
      <Input
        className={classNames('custom-input', {
          // matriculeLast: props.className === 'matricule-last',
          error: hasError,
        })}
        name={name || `_input`}
        placeholder={placeholder}
        type={type || 'text'}
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        autoComplete="off"
        step=".01"
        pattern={pattern}
        {...props}
      />
      {props.leftAddOn && props.leftAddOn}
      {props.popover && (
        <UncontrolledPopover
          trigger="hover"
          placement="bottom"
          target={`input-${name}-consultable`}>
          <PopoverBody className="popover-bd">
            <span className="popover-title text-underline">
              {props.popoverTitle}{' '}
            </span>
            <span className="popover-text">{props.popoverText} </span>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </FormGroup>
  );
}
