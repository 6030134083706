import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Api} from 'api';

import {fichiersStateSelect} from '../selector';
import {setDefaultGaranties, setInterdeps} from '../actions';

export function useFetchGarantie(id, cb, cb2) {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const {devis} = useSelector(fichiersStateSelect);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const value = await Api().get(`/garanties/${id}`);
        setData(value);

        const defaultEnabled =
          value.enabledByDefault ||
          value.interdependances.find((one) => one.type === 'DEFAULT') !==
            undefined;

        dispatch(setInterdeps(value.interdependances));

        cb(defaultEnabled);
        if (defaultEnabled) {
          dispatch(
            setDefaultGaranties({
              [`${value.code}`]: {
                isEnabled: true,
                ...(value.formules[0] && {formule: value.formules[0]._id}),
                garantie: value._id,
              },
            }),
          );
        }
        if (cb2) {
          console.log('value.formules', value.formules);
          const fId = devis?.carteData?.[value.code]?.formule;
          const selectedFormule = fId
            ? value.formules.find((one) => one._id === fId)
            : value.formules[0];
          cb2(selectedFormule || null);
        }
      } catch (e) {
        console.log('e', e);
        setFetching(false);
      }
    };

    fetchData();
  }, [cb, cb2, devis, dispatch, id]);

  return {fetching, data};
}
