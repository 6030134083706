import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';

import downloadApi from 'api/downloadApi';
import {downloadFile} from 'lib/helpers';
import Api from 'api/api';

import {createPdfDeclarationUrl} from '../api/endpoints';
import {fichiersStateSelect, infosStateSelect} from '../selector';
import moment from 'lib/moment';

export function useCreatePdfDeclaration() {
  const [fetchingPdfDeclaration, setfetchingPdfDeclaration] = useState(false);
  const [error, setError] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const dispatch = useDispatch();
  const {files} = useSelector(fichiersStateSelect);
  const infos = useSelector(infosStateSelect);

  async function createPdfDeclaration(payload, compagne) {
    console.log('infos.isNew', infos.isNew);
    try {
      setfetchingPdfDeclaration(true);
      setError(null);
      const createPayload = {
        ...payload,
        ...(infos.matricule1 && {
          immatriculation: infos.isNew
            ? `${infos.matricule1}-WW`
            : `${infos.matricule1}-${infos.matricule2.value}-${infos.matricule3}`,
        }),
        files,
        attestation: infos.attestation,
        cnie: infos.cnie,
        phone: infos.phone,
        date: infos.dateEffet
          ? moment(infos.dateEffet).format('YYYY-MM-DD')
          : '',
        hour: infos.timeEffet ? moment(infos.timeEffet).format('HH:MM') : '',
        circonstances: infos.circonstance,
        accident: infos.accident?.value,
        points: infos.points,
      };
      const url = await downloadApi(createPdfDeclarationUrl(), createPayload);
      downloadFile(url, compagne?.label);
    } catch (err) {
      setError(err);
    } finally {
      setfetchingPdfDeclaration(false);
    }
  }

  return [{fetchingPdfDeclaration, error}, createPdfDeclaration];
}
