import React, {useState, useEffect} from 'react';

import inputsValidation from 'lib/inputsValidation';
import ErrorLabel, {usePasswordCheck} from 'hooks/usePasswordCheck';
import AsyncSelect from 'react-select/async';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
// core components
import AuthNavbar from 'components/Navbars/AuthNavbar';

import './signup-styles.scss';
import {Api} from 'api';

function LoginPage(props) {
  useEffect(() => {
    localStorage.clear();

    document.body.classList.add('login-page');
    document.body.classList.add('signup-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
      document.body.classList.remove('signup-page');
    };
  }, []);

  const [state, setState] = useState({});
  const {isValid: passwordValid} = usePasswordCheck(state.password || '');
  const [openPassword, setOpenPassword] = useState(false);

  console.log('state', state);

  const loadOptions = (inputValue) =>
    new Promise(async (resolve) => {
      try {
        const filter = inputValue ? `$q=${inputValue}` : '';
        const data = await Api().get(
          `/employers/?$top=100&$sort={"name":1}&${filter}`,
        );
        // setEmployers(data.value);
        const formatted = data.value.map((one) => ({
          value: one._id,
          label: one.name,
        }));
        resolve(formatted);
      } catch {
        resolve([]);
      }
    });

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      name: {first: state.firstname, last: state.lastname},
      email: state.email,
      password: state.password,
      employer: state.employer.value,
    };
    props.actions.signup(payload);
  };

  return (
    <div className="app-container signup">
      <AuthNavbar register />
      <div className="app-content bg-image signup-content">
        <Col md="6">
          <Form onSubmit={onSubmit} action="" className="form" method="">
            <Card className="card-login card-plain">
              <CardBody>
                <h3 className="text-black text-bold auth-title">
                  Créer un compte
                </h3>
                <FormGroup>
                  <Label className="text-black" for="reg-lastname">
                    Nom
                  </Label>
                  <Input
                    id="reg-lastname"
                    name="reg-lastname"
                    placeholder=""
                    type="text"
                    value={state.lastname}
                    onChange={(e) =>
                      setState({...state, lastname: e.currentTarget.value})
                    }
                    className="custom-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="text-black" for="reg-firstname">
                    Prénom
                  </Label>
                  <Input
                    id="reg-firstname"
                    name="reg-firstname"
                    placeholder=""
                    type="text"
                    value={state.firstname}
                    onChange={(e) =>
                      setState({...state, firstname: e.currentTarget.value})
                    }
                    className="custom-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="text-black" for="reg-email">
                    E-mail
                  </Label>
                  <Input
                    id="reg-email"
                    name="reg-email"
                    placeholder=""
                    type="email"
                    className="custom-input"
                    value={state.email}
                    onChange={(e) =>
                      setState({
                        ...state,
                        email: e.currentTarget.value,
                        emailError: !inputsValidation(
                          'email',
                          e.currentTarget.value,
                        ),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup className="password-input">
                  <Label className="text-black" for="pwd">
                    Mot de passe
                  </Label>
                  <Input
                    id="pwd"
                    name="pwd"
                    placeholder=""
                    type={openPassword ? 'text' : 'password'}
                    value={state.password}
                    onChange={(e) =>
                      setState({...state, password: e.currentTarget.value})
                    }
                    className="custom-input"
                  />
                  <div
                    onClick={() => setOpenPassword(!openPassword)}
                    className="input-addon">
                    <img
                      alt="+"
                      src={
                        openPassword
                          ? require('assets/img/eye-b.png')
                          : require('assets/img/eye.png')
                      }
                    />
                  </div>
                </FormGroup>
                {state.password && <ErrorLabel password={state.password} />}
                <FormGroup>
                  <Label className="text-black" for="employeur">
                    Nom de l'employeur
                  </Label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onInputChange={(e) => {
                      console.log('input change', e);
                    }}
                    onChange={(select) =>
                      setState({...state, employer: select})
                    }
                  />
                </FormGroup>
              </CardBody>
              <CardFooter className="text-center">
                <Button
                  block
                  className="btn-round on-secondary"
                  color="secondary"
                  size="lg"
                  type="submit"
                  disabled={
                    !state.firstname ||
                    !state.lastname ||
                    !state.employer ||
                    !passwordValid ||
                    props.auth.fetching
                  }>
                  {props.auth.fetching ? (
                    <Spinner size="sm" />
                  ) : (
                    <span> S'inscrire </span>
                  )}
                </Button>
              </CardFooter>
              {props.auth.requestError && (
                <h6 className="auth-error">{props.auth.errorMessage}</h6>
              )}
            </Card>
          </Form>
          <CardFooter className="text-left">
            <div className="text-black" style={{marginTop: '4%'}}>
              <img
                width="100px"
                alt="..."
                src={require('assets/img/Picture1.png')}
              />{' '}
              <span className="text-black" style={{marginTop: '45%'}}>
                © Copyright 2021 AGMA - Tous Droits réservés -
                <a href=" https://agma.ma/mentions-legales-2/" target="_blank">
                  {' '}
                  Mentions légales
                </a>
              </span>
            </div>
          </CardFooter>
          {/*<div className="bottom-cgu">
            <span className="text-black">
              En continuant, vous acceptez les{' '}
              <a className="text-info" href="#/cgus">
                {' '}
                conditions générales d'utilisations{' '}
              </a>{' '}
              et notre{' '}
              <a className="text-info" href="#/cgus">
                {' '}
                politique de confidentialité{' '}
              </a>{' '}
            </span>
                  </div>*/}
        </Col>
      </div>
    </div>
  );
}

export default LoginPage;
