import React, {useState} from 'react';

import {Spinner} from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import {omit} from 'lodash';
import {sortByCode, garantieName} from 'lib/helpers';

import EditableGarantie from './editableGarantie';

import {useFetchCarte} from '../../../hooks/useFetchCarte';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CarteConfig({
  carte,
  onUpdateCarte,
  interdeps,
  ...props
}) {
  const [list, setList] = useState({});
  const [displayAlert, setDisplayAlert] = useState({
    alert: false,
    type: '',
    source: '',
    target: '',
  });
  const {fetching, data} = useFetchCarte(carte, setList);

  if (fetching) {
    return (
      <div className="packs-view">
        <Spinner size="sm" />
      </div>
    );
  }

  const updateSelection = (id, code, formule) => {
    // the target of exclusion
    const gExclusions = interdeps.filter(
      (one) =>
        one.type === 'EXCL' && (one.source === code || one.target === code),
    );
    // the targets of inclusion
    const gInclusions = interdeps.filter(
      (one) =>
        one.type === 'INCL' && (one.source === code || one.target === code),
    );
    // the target of exigence
    const gExigences = interdeps.filter(
      (one) =>
        one.type === 'EXIG' && (one.source === code || one.target === code),
    );

    let newList = {
      ...list,
      [`${code}`]: {
        ...list[code],
        ...(formule && {formule}),
        selected: !list[code]?.selected || false,
        garantie: id,
      },
    };

    // console.log('gExclusions', gExclusions);
    // console.log('gInclusions', gInclusions);
    // console.log('gExigences', gExigences);

    gInclusions.forEach((g) => {
      newList = {
        ...newList,
        [`${g.source}`]: {
          ...newList[g.source],
          selected:
            g.target === code
              ? newList[g.target].selected
              : newList[g.source].selected,
          garantie: g.garantie,
        },
      };
    });

    gExclusions.forEach((g) => {
      if (
        (g.target === code && newList[g.source].selected) ||
        (g.source === code && newList[g.target].selected)
      ) {
        setDisplayAlert({
          alert: true,
          type: 'exclue',
          source: g.source,
          target: g.target,
        });
      }
      newList = {
        ...newList,
        [`${g.target}`]: {
          ...newList[g.target],
          selected:
            g.target === code && newList[g.source].selected
              ? false
              : newList[g.target].selected,
          garantie: g.target === code ? id : g.garantie,
        },
        [`${g.source}`]: {
          ...newList[g.source],
          selected:
            g.source === code
              ? !newList[g.target].selected && newList[g.source].selected
              : newList[g.source].selected,
          garantie: g.garantie,
        },
      };
    });

    gExigences.forEach((g) => {
      if (
        g.source === code &&
        !list[code].selected &&
        !newList[g.target].selected
      ) {
        setDisplayAlert({
          alert: true,
          type: 'exige',
          source: g.source,
          target: g.target,
        });
      }
      newList = {
        ...newList,
        [`${g.source}`]: {
          ...newList[g.source],
          selected:
            g.source === code &&
            newList[g.source].selected &&
            newList[g.target].selected
              ? true
              : false,
          garantie: g.garantie,
        },
      };
    });

    setList(newList);
    // console.log('to update newList', newList);
    onUpdateCarte(newList);
  };

  return (
    <div className="packs-container">
      <span className="step-field">Paramétrez votre assurance</span>
      {Object.values(data)
        .sort(sortByCode)
        .map((g) => {
          const enabled = list[g.code]?.selected;
          return (
            <EditableGarantie
              key={g._id}
              garantie={g._id}
              selected={enabled}
              onChangeSelect={(code, formule) =>
                updateSelection(g._id, code, formule)
              }
              onSelectFormule={(f) => {
                setList({
                  ...list,
                  [`${g.code}`]: {
                    ...list[g.code],
                    formule: f._id,
                    formuleData: omit(f, ['_id', 'id', '__v']),
                  },
                });
                onUpdateCarte({
                  ...list,
                  [`${g.code}`]: {
                    ...list[g.code],
                    formule: f._id,
                    formuleData: omit(f, ['_id', 'id', '__v']),
                    garantie: g._id,
                  },
                });
              }}
            />
          );
        })}
      <Snackbar
        open={displayAlert.alert}
        onClose={() =>
          setDisplayAlert({
            type: '',
            source: '',
            target: '',
            alert: false,
          })
        }
        TransitionComponent={Fade}
        key={Fade.name}>
        <Alert
          onClose={() =>
            setDisplayAlert({
              type: '',
              source: '',
              target: '',
              alert: false,
            })
          }
          severity="info">
          {displayAlert.type === 'exclue'
            ? `La garantie ${garantieName(
                displayAlert.source,
              )} et la garantie ${garantieName(
                displayAlert.target,
              )} ne peuvent être souscrites en même temps`
            : `La garantie ${garantieName(displayAlert.source)} ${
                displayAlert.type
              } la garantie ${garantieName(displayAlert.target)}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
