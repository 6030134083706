import React from 'react';

export const DOCS_TYPES = [
  {label: 'Constat à l’amiable', value: 'constatAmiable', prepo: 'le'},
  {label: 'PV de police/gendarmerie', value: 'pvPolice', prepo: 'le'},
  {label: 'Récépissé du PV ', value: 'recepissePV', prepo: 'le'},
  {
    label: 'Déclaration sur l’honneur',
    value: 'declarationHon',
    prepo: 'la',
  },
];

export const INSURANCES = [
  {label: 'RMA Assurance', value: 'RMA Assurance', cmp: 'rma'},
  {label: 'WAFA Assurance', value: 'WAFA Assurance', cmp: 'wafa'},
  {
    label: 'Autre (SAHAM, AXA, AtlantaSanad, Allianz, ...)',
    value: 'Autre',
    cmp: 'agma',
  },
];

export const POINTS_CHOCS = [
  {label: 'Avant', value: 'Avant'},
  {label: 'Arrière', value: 'Arrière'},
  {label: 'Latéral droit', value: 'Latéral droit'},
  {label: 'Latéral Gauche', value: 'Latéral Gauche'},
  {label: 'Pare-brise', value: 'Pare-brise'},
  {label: 'Lunette', value: 'Lunette'},
  {label: 'Glace latérale', value: 'Glace latérale'},
  {label: 'Toit panoramique', value: 'Toit panoramique'},
  {label: 'Autre', value: 'Autre'},
];

export const MOTORS = [
  {label: 'Diesel', value: 'DIESEL'},
  {label: 'Essence', value: 'ESSENCE'},
  {label: 'Hybride', value: 'HYBRID'},
];

export const SINISTER_CAUSES = [
  {
    label: 'Collision avec un autre véhicule',
    value: 'Collision avec un autre véhicule',
  },
  {
    label: 'Collision avec un objet / fortuit',
    value: 'Collision avec un objet / fortuit',
  },
  {label: 'Bris de glace', value: 'Bris de glace'},
  {label: 'Vol', value: 'Vol'},
  {label: 'Incendie', value: 'Incendie'},
  {label: 'Renversement', value: 'Renversement'},
];

export const PUISSANCE_FISCAL = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '11+',
    value: 11,
  },
];

export const MATRICULE_LETTERS = [
  {value: 'A', label: 'أ'},
  {value: 'B', label: 'ب'},
  {value: 'D', label: 'د'},
  {value: 'H', label: 'ﻫ'},
  {value: 'E', label: 'و'},
  {value: 'CC', label: 'CC'},
  {value: 'CI', label: 'CI'},
  {value: 'OI', label: 'OI'},
  {value: 'CD', label: 'CD'},
  {value: 'CMD', label: 'CMD'},
  {value: 'PAT', label: 'PAT'},
  {value: 'M', label: 'M'},
  {value: '.', label: '.'},
];

export const PARENTAL_LINK = [
  {
    label: 'Conjoint',
    value: 'CONJOINT',
  },
  {
    label: 'Ascendant',
    value: 'ASC',
  },
  {
    label: 'Descendant',
    value: 'DESC',
  },
];

export const CITIES = [
  {label: 'AKLIM', value: 'AKLIM'},
  {label: 'AGADIR', value: 'AGADIR'},
  {label: 'AJDIR', value: 'AJDIR'},
  {label: 'AL HOUCEIMA', value: 'AL HOUCEIMA'},
  {label: 'ASILAH', value: 'ASILAH'},
  {label: 'BENI CHIKER', value: 'BENI CHIKER'},
  {label: 'BENI ANSAR', value: 'BENI ANSAR'},
  {label: 'BENI MELLAL', value: 'BENI MELLAL'},
  {label: 'BEN SLIMANE', value: 'BEN SLIMANE'},
  {label: 'BERKANE', value: 'BERKANE'},
  {label: 'BERRECHID', value: 'BERRECHID'},
  {label: 'BHALIL', value: 'BHALIL'},
  {label: 'BOUARFA', value: 'BOUARFA'},
  {label: 'BOUANANE', value: 'BOUANANE'},
  {label: 'BOUDNIB', value: 'BOUDNIB'},
  {label: 'Boufakrane', value: 'Boufakrane'},
  {label: 'BOUGEDRA', value: 'BOUGEDRA'},
  {label: 'BOUIZAKARNE', value: 'BOUIZAKARNE'},
  {label: 'BOUKNADEL', value: 'BOUKNADEL'},
  {label: 'Bouskoura', value: 'Bouskoura'},
  {label: 'BOUZNIKA', value: 'BOUZNIKA'},
  {label: 'CASABLANCA (DAR EL BEIDA)', value: 'CASABLANCA (DAR EL BEIDA)'},
  {label: 'CHEFCHAOUEN', value: 'CHEFCHAOUEN'},
  {label: 'CHEMAIA', value: 'CHEMAIA'},
  {label: 'CHICHAOUA', value: 'CHICHAOUA'},
  {
    label: 'DAKHLA (Ad Dakhla, Villa Cisneros)',
    value: 'DAKHLA (Ad Dakhla, Villa Cisneros)',
  },
  {label: 'DAR GUEDDARI', value: 'DAR GUEDDARI'},
  {label: 'DRIOUCH', value: 'DRIOUCH'},
  {label: 'EL GUERDANE', value: 'EL GUERDANE'},
  {label: 'EL HAJEB', value: 'EL HAJEB'},
  {label: 'Errachidia (Ksar es-Souk)', value: 'Errachidia (Ksar es-Souk)'},
  {label: 'ESSAOUIRA MAGADOR', value: 'ESSAOUIRA MAGADOR'},
  {label: 'FARCIA', value: 'FARCIA'},
  {label: 'FES', value: 'FES'},
  {label: 'GUELMIM', value: 'GUELMIM'},
  {label: 'GOULMIMA', value: 'GOULMIMA'},
  {label: 'GUELTA ZEMMOUR', value: 'GUELTA ZEMMOUR'},
  {label: 'GUERGUERAT', value: 'GUERGUERAT'},
  {label: 'HARHOURA', value: 'HARHOURA'},
  {label: 'IFRANE', value: 'IFRANE'},
  {label: 'INEZGANE', value: 'INEZGANE'},
  {label: 'KHEMISSET', value: 'KHEMISSET'},
  {label: 'KHENICHET', value: 'KHENICHET'},
  {label: 'KHENIFRA', value: 'KHENIFRA'},
  {label: 'KHOURIBGA', value: 'KHOURIBGA'},
  {label: 'LAAYOUNE', value: 'LAAYOUNE'},
  {label: 'LAGOUIRA', value: 'LAGOUIRA'},
  {label: 'LARACHE', value: 'LARACHE'},
  {label: 'MARRAKECH', value: 'MARRAKECH'},
  {label: 'MARTIL', value: 'MARTIL'},
  {label: 'MEDIOUNA', value: 'MEDIOUNA'},
  {label: 'MEHDIA', value: 'MEHDIA'},
  {label: 'MEKNES', value: 'MEKNES'},
  {label: 'MIRLEFT', value: 'MIRLEFT'},
  {label: 'MOHAMMEDIA', value: 'MOHAMMEDIA'},
  {label: 'MOQRISSET', value: 'MOQRISSET'},
  {label: 'NADOR', value: 'NADOR'},
  {label: 'OUARZAZATE', value: 'OUARZAZATE'},
  {label: 'OUALIDIA', value: 'OUALIDIA'},
  {label: 'OUEZZANE', value: 'OUEZZANE'},
  {label: 'OUJDA', value: 'OUJDA'},
  {label: 'OUKAIMEDEN', value: 'OUKAIMEDEN'},
  {label: 'OULMES', value: 'OULMES'},
  {label: 'OUNAGHA', value: 'OUNAGHA'},
  {label: 'RABAT', value: 'RABAT'},
  {label: 'SAFI', value: 'SAFI'},
  {label: 'SAIDIA', value: 'SAIDIA'},
  {label: 'SALE', value: 'SALE'},
  {label: 'SEFROU', value: 'SEFROU'},
  {label: 'SETTAT', value: 'SETTAT'},
  {label: 'SIDI KACEM ( CHERADA )', value: 'SIDI KACEM ( CHERADA )'},
  {label: 'SIDI SLIMANE', value: 'SIDI SLIMANE'},
  {label: 'SKHIRAT', value: 'SKHIRAT'},
  {label: 'SMARA SEMARA', value: 'SMARA SEMARA'},
  {label: 'TADDERT', value: 'TADDERT'},
  {label: 'TANGER', value: 'TANGER'},
  {label: 'TAN TAN', value: 'TAN TAN'},
  {label: 'TAMANAR', value: 'TAMANAR'},
  {label: 'TAZA', value: 'TAZA'},
  {label: 'TAZNAKHT', value: 'TAZNAKHT'},
  {label: 'TELOUET', value: 'TELOUET'},
  {label: 'TEMARA', value: 'TEMARA'},
  {label: 'TEMSIA', value: 'TEMSIA'},
  {label: 'TETOUAN', value: 'TETOUAN'},
  {label: 'TIFARITI', value: 'TIFARITI'},
  {label: 'TIFLET', value: 'TIFLET'},
  {label: 'TIZNIT', value: 'TIZNIT'},
  {label: 'TIZTOUTINE', value: 'TIZTOUTINE'},
  {label: 'TIFELT', value: 'TIFELT'},
  {label: 'YOUSSOUFIA', value: 'YOUSSOUFIA'},
  {label: 'ZAGORA', value: 'ZAGORA'},
];

export const GARANTIES = [
  {
    name: 'Responsabilité Civile',
    code: 'g1',
    acceptFormules: false,
    enabledByDefault: true,
    description:
      'Couvre la responsabilité civile de l’assuré à raison des dommages corporels et matériels causés aux tiers par le véhicule assuré. ',
  },
  {
    name: 'Evènement catastrophique',
    code: 'g2',
    acceptFormules: false,
    enabledByDefault: true,
    description:
      'Garantie obligatoire par la loi 110-14, couvre les évènements catastrophiques provenant du fait de la nature et du fait de l’homme. ',
  },
  {
    name: 'Défense et recours',
    code: 'g3',
    acceptFormules: true,
    description:
      "L’assureur s'engage à procéder à concurrence du montant garanti à toutes les interventions amiables et à intenter toutes actions judiciaires tendant à défendre l'assuré ou obtenir réparation des préjudices subis par celui-ci.",
  },
  {
    name: 'Personnes transportées (PTA)',
    code: 'g4',
    acceptFormules: true,
    description:
      'L’assureur garantit le paiement des indemnités définies au contrat dans le cas où la personne assurée serait victime d’un dommage corporel causé par un accident de la circulation.',
  },
  {
    name: 'Tierce (Dommages Tous Accidents)',
    code: 'g5',
    acceptFormules: true,
    description:
      'Couvre les dommages accidentels subis par le véhicule assuré, y compris les accessoires et pièces de rechange dont le catalogue du constructeur prévoit la livraison en même temps que celle du véhicule et ce ,en cas de Collision avec un autre véhicule ou choc avec un corps fixe ou mobile ou renversement du véhicule.',
  },
  {
    name: 'Dommage Collision (DC)',
    code: 'g6',
    acceptFormules: true,
    description:
      'Couvre les dommages accidentels subis par le véhicule assuré, consécutifs à une collision avec un véhicule terrestre à moteur identifié ou avec un piéton identifié.',
  },
  {
    name: 'Vol',
    code: 'g7',
    acceptFormules: true,
    description:
      'Couvre les dommages résultant de  la  disparition du véhicule ou de sa  détérioration  à  la  suite  d’un  vol  ou  d’une  tentative de vol.',
  },
  {
    name: 'Vol des rétroviseurs',
    code: 'g8',
    acceptFormules: true,
    description: 'Couvre le vol  isolé des rétroviseurs latéraux. ',
  },
  {
    name: 'Accessoires extérieurs',
    code: 'g9',
    acceptFormules: true,
  },
  {
    name: 'Incendie',
    code: 'g10',
    acceptFormules: true,
    description:
      "Couvre les dommages subis par le véhicule assuré, lorsque ces dommages résultent de l'incendie, Explosion ou chute de la foudre.",
  },
  {
    name: 'Bris de glace',
    code: 'g11',
    acceptFormules: true,
    description:
      'Couvre les dommages causés ou non par un accident, subis par:  pare-brise ; -  la lunette arrière ; -  les glaces latérales ; -  les glaces des portières ; -  les glaces du toit ouvrant.',
  },
  {
    name: 'Bris des rétroviseurs',
    code: 'g12',
    acceptFormules: true,
    description:
      'Couvre le bris des feux avant/arrière du véhicule, les blocs optiques intégrés à la carrosserie ; Le bris des miroirs de rétroviseurs,',
  },
  {
    name: 'Evènements climatiques',
    code: 'g13',
    acceptFormules: true,
  },
  {
    name: 'Inondation',
    code: 'g14',
    acceptFormules: true,
    description:
      "Couvre les dommages matériels directs subis par le véhicule assuré et ayant pour cause déterminante l’intensité anormale de l’eau provoquée par le ruissellement, l'engorgement et le refoulement des égouts, les marées, les raz-de-marée, le débordement des sources, de cours d'eau, par les inondations et plus généralement par la mer et les autres plans d'eau naturels ou artificiels ainsi que par les masses de neige ou de glace en mouvement. ",
  },
  {
    name: 'Rachat de vétusté',
    code: 'g15',
    acceptFormules: true,
    description:
      "La garantie couvre le montant de la vétusté devant rester à la charge de l'Assuré au titre de la garantie principale lorsqu'il s'agit d'un sinistre partiel affectant une des garanties Tierce, Vol,Incendie.",
  },
  {
    name: 'Perte financière',
    code: 'g16',
    acceptFormules: true,
    description:
      'Remboursement en cas de perte totale du véhicule, acheté à crédit, de la différence, au jour du sinistre, entre le montant de l’indemnité de résiliation due au titre du crédit contracté et l’indemnité due au titre de la garantie de base concernée.',
  },
  {
    name: 'Vol des pneumatiques',
    code: 'g17',
    acceptFormules: true,
  },
  {
    name: 'Dommage Collision Etendue',
    code: 'g18',
    acceptFormules: true,
  },
  {
    name: 'Autres Garanties',
    code: 'g19',
    acceptFormules: true,
    description: (
      <span>
        Merci contacter Service Auto <br /> 05 22 48 80 50 – ProdAuto@agma.co.ma
      </span>
    ),
  },
  {
    name: 'Garantie 20',
    code: 'g20',
    acceptFormules: true,
  },
];
