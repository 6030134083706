import React from 'react';

import {Spinner, UncontrolledPopover, PopoverBody} from 'reactstrap';

import {GARANTIES} from 'config/app';
import apiConfig from 'config/apiConfig';

import {useFetchPack} from '../../../hooks/useFetchPack';

const {BASE_URL} = apiConfig;

export default function PackView({pack, ...props}) {
  const {
    fetching,
    data: {garanties = []},
  } = useFetchPack(pack._id); //checkForG11

  if (fetching) {
    return (
      <div className="packs-view">
        <Spinner size="sm" />
      </div>
    );
  }
  return (
    <div className="packs-view">
      <img src={`${BASE_URL}${pack.imageUrl}`} alt="i" className="packImg" />
      <span className="formule-title">{pack.name}</span>
      <div>
        {garanties.map((g) => {
          const gLocal = GARANTIES.find((one) => one.code === g.code);
          return (
            <>
              <div id={`popover${g._id}`} key={g._id} className="pack-garantie">
                <img
                  alt="ck"
                  src={require('assets/img/gChecked.png')}
                  className="g-checked"
                />
                <span style={{cursor: 'default'}}>{gLocal.name}</span>
              </div>
              <UncontrolledPopover
                trigger="hover"
                placement="bottom"
                target={`popover${g._id}`}>
                <PopoverBody className="popover-bd">
                  <span className="popover-g-name">{gLocal.name} </span>
                  <span className="popover-g-desc">{gLocal.description} </span>
                </PopoverBody>
              </UncontrolledPopover>
            </>
          );
        })}
      </div>
    </div>
  );
}
