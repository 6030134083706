import React, {useState} from 'react';

import {UncontrolledPopover, PopoverBody} from 'reactstrap';
import DropDownInput from 'components/DropDownInput';

import {GARANTIES} from 'config/app';

import {useFetchGarantie} from '../../../hooks/useFetchGarantie';

export default function EditableGarantie({onSelectFormule, ...props}) {
  const [defaultEnabled, setDefaultEnabled] = useState(false);
  const [formule, setFormule] = useState(null);

  const {data} = useFetchGarantie(
    props.garantie,
    setDefaultEnabled,
    setFormule,
  );

  const gLocal = GARANTIES.find((one) => one.code === data.code);

  const hasFormules = data?.formules?.length > 1;

  const typeMap = {
    TXT: 'Texte',
    VDG: 'Valeur des glaces',
    VN: 'Valeur neuve',
    VV: 'Valeur vénale',
  };

  const plafond_garantie = (f) => {
    if (f?.isG6) {
      return f?.desc_plafond_content;
    }
    return f?.desc_plafond_type === 'TXT'
      ? f?.desc_plafond_content
      : typeMap[f?.desc_plafond_type || 'TXT'];
  };

  return (
    <>
      <div className="pack-garantie">
        {data.enabledByDefault || defaultEnabled ? (
          <div className="g-select">
            <img
              alt="v"
              src={require('assets/img/defaultChecked.png')}
              className="step-checkmark"
            />
          </div>
        ) : (
          <div
            className="g-select"
            onClick={() => {
              const defaultF = data?.formules?.[0]
                ? data?.formules?.[0]._id
                : null;
              props.onChangeSelect(data?.code, formule?.value || defaultF);
            }}>
            <img
              alt="v"
              src={
                props.selected
                  ? require('assets/img/checked.png')
                  : require('assets/img/noChecked.png')
              }
              className="step-checkmark"
            />
          </div>
        )}
        <span style={{cursor: 'default'}} id={`Popover-${data._id}`}>
          {data?.name}
        </span>
        {data._id && (
          <UncontrolledPopover
            trigger="hover"
            placement="bottom"
            target={`Popover-${data._id}`}>
            <PopoverBody className="popover-bd">
              <span className="popover-g-name">{data?.name} </span>
              <span className="popover-g-desc">{gLocal?.description} </span>
            </PopoverBody>
          </UncontrolledPopover>
        )}
      </div>
      {props.selected && (
        <div className="garantie-formule-select">
          {hasFormules && (
            <>
              <span className="step-field">Choisissez une formule</span>
              <DropDownInput
                selected={formule}
                labelBold
                options={data.formules.map((f, i) => ({
                  label: `Formule ${i + 1}: `,
                  labelComplement: `Plafond de la garantie ${plafond_garantie(
                    f,
                  )} ${
                    f.desc_franchise ? ' | Franchise ' + f.desc_franchise : ''
                  } `,
                  value: f._id,
                  ...f,
                }))}
                setSelected={(v) => {
                  setFormule(v);
                  onSelectFormule(v);
                }}
              />
            </>
          )}
          {formule && (
            <div className="formule-container">
              <span className="step-field">Plafond de la garantie</span>
              <p className="formule-description">{plafond_garantie(formule)}</p>
              <span className="step-field">Franchise</span>
              <p className="formule-description">{formule?.desc_franchise} </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
