import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

// reactstrap components
import {
  // Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';

import {Api} from 'api';
import {/*setCurrentUser, */ logoutSuccess} from 'actions';
import {useDispatch} from 'react-redux';

function TopNavbar(props) {
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();
  const {isConnected, history} = props;

  useEffect(() => {
    setConnected(isConnected);
  }, [isConnected]);

  const logout = async () => {
    try {
      await Api().get('/auth/users/signout');
      dispatch(logoutSuccess());
      history.replace('/auth/landing');
    } catch (error) {
      dispatch(logoutSuccess());
      history.replace('/auth/landing');
    }
  };

  return (
    <div className="landing-navbar">
      <div className="topbar-right">
        {!props.logoOnly && connected ? (
          <UncontrolledDropdown className="button-dropdown button-shaddow">
            <DropdownToggle
              caret
              data-toggle="dropdown"
              href="#pablo"
              id="navbarDropdown"
              tag="a"
              onClick={(e) => e.preventDefault()}>
              <span className="button-bar"></span>
              <span className="button-bar"></span>
              <span className="button-bar"></span>
            </DropdownToggle>
            <DropdownMenu aria-labelledby="navbarDropdown">
              <DropdownItem to="/" tag={Link}>
                <span className="home-navlink text-semi"> Accueil</span>
              </DropdownItem>
              <DropdownItem to="/requests" tag={Link}>
                <span className="home-navlink text-semi"> Mes demandes</span>
              </DropdownItem>
              <DropdownItem to="/sinistres" tag={Link}>
                <span className="home-navlink text-semi"> Mes sinistres</span>
              </DropdownItem>
              <DropdownItem to="/my-space" tag={Link}>
                <span className="home-navlink text-semi">
                  {' '}
                  Gérer mon espace
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <div />
        )}
      </div>
      <img
        alt="logo"
        src={require('../../assets/img/logo.png')}
        className="app-logo"
        onClick={() => {
          history.push('/landing');
        }}
        tag="button"
      />
      {!props.logoOnly ? (
        <div className="topbar-right">
          {connected ? (
            <div onClick={() => logout()} className="nav-right-item">
              Se déconnecter
            </div>
          ) : (
            <>
              <div
                onClick={() => {
                  history.push('/auth/signin');
                }}
                className="nav-right-item">
                Se connecter
              </div>
              {/*<div
                onClick={() => {
                  history.push('/auth/signup');
                }}
                className="nav-right-item">
                S'inscrire
              </div>*/}
            </>
          )}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default withRouter(TopNavbar);
