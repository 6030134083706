import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../../actions';

import {fichiersStateSelect, currentUserStateSelect} from '../../selector';

import Fichiers from './component';

const FichiersContainer = (props) => {
  const currentUser = useSelector(currentUserStateSelect);
  const fichiers = useSelector(fichiersStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  return (
    <Fichiers
      fichiers={fichiers}
      currentUser={currentUser}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default FichiersContainer;
