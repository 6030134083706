import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../../actions';

import {userDataStateSelect, currentUserStateSelect} from '../../selector';

import UserData from './component';

const UserDataContainer = (props) => {
  const currentUser = useSelector(currentUserStateSelect);
  const userData = useSelector(userDataStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  return (
    <UserData
      userData={userData}
      currentUser={currentUser}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default UserDataContainer;
