import React, {useState} from 'react';
import classNames from 'classnames';

import Avatar from '@material-ui/core/Avatar';
import ButtonTag from 'components/ButtonTag';

import downloadApi from 'api/downloadApi';
import {downloadFile} from 'lib/helpers';

import StepFooter from '../../stepFooter';
import {useFetchConvention} from '../../hooks/useFetchConvention';
import {useUpdateDevis} from 'hooks/useUpdateDevis';

export default function Fichiers(props) {
  const [paymentMode, setPaymentMode] = useState('');
  const [cin, setCin] = useState({recto: null, verso: null});
  const [carteGrise, setCarteGrise] = useState({recto: null, verso: null});
  const [permisConduire, setPermisConduire] = useState({
    recto: null,
    verso: null,
  });
  const [attestationTravail, setAttestation] = useState(null);
  const [cession, setCession] = useState(null);
  const [bulletin, setBulletin] = useState(null);

  const [currentFile, setCurrentFile] = useState(null);
  const [hasError, setHasError] = useState(false);

  const {currentUser, fichiers} = props;
  const {
    data: {paymentModes = [], ...convention},
  } = useFetchConvention(currentUser?.employer?.convention);

  const [updateValues, updateDevis] = useUpdateDevis();

  const onSelectFile = (current) => {
    setCurrentFile(current);
    document.getElementById('file-select').click();
  };

  const downloadCessionD = async () => {
    try {
      const url = await downloadApi('/me/cd');
      downloadFile(url, 'cession_delegation.xlsx');
    } catch (e) {}
  };

  const invalidForm =
    !paymentMode ||
    (!cin.recto && !cin.verso) ||
    (!carteGrise.recto && !carteGrise.verso) ||
    (!permisConduire.recto && !permisConduire.verso);
  const onSubmit = () => {
    if (invalidForm) {
      setHasError(true);
    } else {
      const payload = {
        paymentMode,
        ...(carteGrise.recto && {cgRecto: carteGrise.recto.file}),
        ...(carteGrise.verso && {cgVerso: carteGrise.verso.file}),
        ...(permisConduire.recto && {permisRecto: permisConduire.recto.file}),
        ...(permisConduire.verso && {permisVerso: permisConduire.verso.file}),
        ...(cin.recto && {cnieRecto: cin.recto.file}),
        ...(cin.verso && {cnieVerso: cin.verso.file}),
        ...(cession && {cessionSalaire: cession.file}),
        ...(attestationTravail && {attTravail: attestationTravail.file}),
        ...(bulletin && {bullSoucription: bulletin.file}),
      };
      setHasError(false);
      updateDevis(payload, fichiers.devis?._id);
    }
  };

  return (
    <div className="step-container">
      <h3 className="page-title-text">Pièces jointes</h3>
      <div className="step-form">
        <div>
          <span className="step-field"> Choisissez le type de paiement</span>
          <div className="btn-grp-h">
            {paymentModes.includes('prelevement') && (
              <ButtonTag
                onClick={() => setPaymentMode('prelevement')}
                label="Prélèvement sur salaire"
                active={paymentMode === 'prelevement'}
              />
            )}
            {paymentModes.includes('cash') && (
              <ButtonTag
                onClick={() => setPaymentMode('cash')}
                label="Au Comptant"
                active={paymentMode === 'cash'}
              />
            )}
          </div>
        </div>
        <div>
          <span
            className={classNames('step-field', {
              error: hasError && !carteGrise.recto && !carteGrise.verso,
            })}>
            <span
              className={classNames('files-name', {
                error: hasError && !carteGrise.recto && !carteGrise.verso,
              })}>
              Carte grise
            </span>
            (Recto / Verso)
          </span>
          <div className="files-container">
            <div
              onClick={() => onSelectFile('carteGrise')}
              className="add-file">
              <img
                src={require('assets/img/add-photos.png')}
                className="add-btn"
                alt="+"
              />
            </div>
            <div className="files-content">
              {carteGrise.recto && (
                <div className="photo-preview">
                  <Avatar
                    variant="rounded"
                    alt="logo"
                    src={carteGrise.recto.url}
                  />
                  <img
                    alt="x"
                    src={require('assets/img/rm-icon.png')}
                    className="pack-photo-remove"
                    onClick={() => setCarteGrise({...carteGrise, recto: null})}
                  />
                </div>
              )}
              {carteGrise.verso && (
                <div className="photo-preview">
                  <Avatar
                    variant="rounded"
                    alt="logo"
                    src={carteGrise.verso.url}
                  />
                  <img
                    alt="x"
                    src={require('assets/img/rm-icon.png')}
                    className="pack-photo-remove"
                    onClick={() => setCarteGrise({...carteGrise, verso: null})}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <span
            className={classNames('step-field', {
              error: hasError && !permisConduire.recto && !permisConduire.verso,
            })}>
            <span
              className={classNames('files-name', {
                error:
                  hasError && !permisConduire.recto && !permisConduire.verso,
              })}>
              Permis de conduire
            </span>
            (Recto / Verso)
          </span>
          <div className="files-container">
            <div
              onClick={() => onSelectFile('permisConduire')}
              className="add-file">
              <img
                src={require('assets/img/add-photos.png')}
                className="add-btn"
                alt="+"
              />
            </div>
            <div className="files-content">
              {permisConduire.recto && (
                <div className="photo-preview">
                  <Avatar
                    variant="rounded"
                    alt="logo"
                    src={permisConduire.recto.url}
                  />
                  <img
                    alt="x"
                    src={require('assets/img/rm-icon.png')}
                    className="pack-photo-remove"
                    onClick={() =>
                      setCarteGrise({...permisConduire, recto: null})
                    }
                  />
                </div>
              )}
              {permisConduire.verso && (
                <div className="photo-preview">
                  <Avatar
                    variant="rounded"
                    alt="logo"
                    src={permisConduire.verso.url}
                  />
                  <img
                    alt="x"
                    src={require('assets/img/rm-icon.png')}
                    className="pack-photo-remove"
                    onClick={() =>
                      setCarteGrise({...permisConduire, verso: null})
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <span
            className={classNames('step-field', {
              error: hasError && !cin.recto && !cin.verso,
            })}>
            <span
              className={classNames('files-name', {
                error: hasError && !cin.recto && !cin.verso,
              })}>
              CIN
            </span>
            (Recto / Verso)
          </span>
          <div className="files-container">
            <div onClick={() => onSelectFile('cnie')} className="add-file">
              <img
                src={require('assets/img/add-photos.png')}
                className="add-btn"
                alt="+"
              />
            </div>
            <div className="files-content">
              {cin.recto && (
                <div className="photo-preview">
                  <Avatar variant="rounded" alt="logo" src={cin.recto.url} />
                  <img
                    alt="x"
                    src={require('assets/img/rm-icon.png')}
                    className="pack-photo-remove"
                    onClick={() => setCarteGrise({...cin, recto: null})}
                  />
                </div>
              )}
              {cin.verso && (
                <div className="photo-preview">
                  <Avatar variant="rounded" alt="logo" src={cin.verso.url} />
                  <img
                    alt="x"
                    src={require('assets/img/rm-icon.png')}
                    className="pack-photo-remove"
                    onClick={() => setCarteGrise({...cin, verso: null})}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {paymentMode === 'prelevement' && (
          <div>
            <span className="step-field">
              <span className="files-name">
                Cession de délégation sur salaire
              </span>
              (optionnel)
            </span>
            <div className="files-container">
              <div onClick={() => onSelectFile('cession')} className="add-file">
                <img
                  src={require('assets/img/add-photos.png')}
                  className="add-btn"
                  alt="+"
                />
              </div>
              <div className="files-content">
                {cession && (
                  <div className="photo-preview">
                    <Avatar variant="rounded" alt="logo" src={cession.url} />
                    <img
                      alt="x"
                      src={require('assets/img/rm-icon.png')}
                      className="pack-photo-remove"
                      onClick={() => setCession(null)}
                    />
                  </div>
                )}
              </div>
              <div className="cession-file">
                <span onClick={downloadCessionD} className="download">
                  Merci de télécharger ce document
                </span>{' '}
                et le renseigner. Après visa et cachet par votre direction des
                ressources humaines, ce document devra être transmis à nos
                services de production via mail à l’adresse Prodauto@agma.co.ma
              </div>
            </div>
            <span className="step-field">
              <span className="files-name">Bulletin de souscription </span>
              (optionnel)
            </span>
            <div className="files-container">
              <div
                onClick={() => onSelectFile('bulletin')}
                className="add-file">
                <img
                  src={require('assets/img/add-photos.png')}
                  className="add-btn"
                  alt="+"
                />
              </div>
              <div className="files-content">
                {bulletin && (
                  <div className="photo-preview">
                    <Avatar variant="rounded" alt="logo" src={bulletin.url} />
                    <img
                      alt="x"
                      src={require('assets/img/rm-icon.png')}
                      className="pack-photo-remove"
                      onClick={() => setBulletin(null)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {paymentMode === 'cash' && (
          <div>
            <span className="step-field">
              <span className="files-name">
                Attestation de travail ou Badge
              </span>
              (optionnel)
            </span>
            <div className="files-container">
              <div
                onClick={() => onSelectFile('attestationTravail')}
                className="add-file">
                <img
                  src={require('assets/img/add-photos.png')}
                  className="add-btn"
                  alt="+"
                />
              </div>
              <div className="files-content">
                {attestationTravail && (
                  <div className="photo-preview">
                    <Avatar
                      variant="rounded"
                      alt="logo"
                      src={attestationTravail.url}
                    />
                    <img
                      alt="x"
                      src={require('assets/img/rm-icon.png')}
                      className="pack-photo-remove"
                      onClick={() => setAttestation(null)}
                    />
                  </div>
                )}
              </div>
            </div>
            <span className="step-field">
              <span className="files-name">Bulletin de souscription </span>
              (optionnel)
            </span>
            <div className="files-container">
              <div
                onClick={() => onSelectFile('bulletin')}
                className="add-file">
                <img
                  src={require('assets/img/add-photos.png')}
                  className="add-btn"
                  alt="+"
                />
              </div>
              <div className="files-content">
                {bulletin && (
                  <div className="photo-preview">
                    <Avatar variant="rounded" alt="logo" src={bulletin.url} />
                    <img
                      alt="x"
                      src={require('assets/img/rm-icon.png')}
                      className="pack-photo-remove"
                      onClick={() => setBulletin(null)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <input
        className="file-input-hidden"
        type="file"
        id="file-select"
        accept="image/*"
        onChange={(e) => {
          // e.preventDefault();

          const file = e.target.files[0];
          console.log('file', file);
          const url = URL.createObjectURL(file);

          const reader = new FileReader();
          reader.onloadend = () => {
            if (currentFile === 'carteGrise') {
              setCarteGrise({
                recto:
                  carteGrise.recto === null ? {file, url} : carteGrise.recto,
                verso: carteGrise.recto === null ? null : {file, url},
              });
            }
            if (currentFile === 'permisConduire') {
              setPermisConduire({
                recto:
                  permisConduire.recto === null
                    ? {file, url}
                    : permisConduire.recto,
                verso: permisConduire.recto === null ? null : {file, url},
              });
            }
            if (currentFile === 'cnie') {
              setCin({
                recto: cin.recto === null ? {file, url} : cin.recto,
                verso: cin.recto === null ? null : {file, url},
              });
            }
            if (currentFile === 'cession') {
              setCession({file, url});
            }
            if (currentFile === 'attestationTravail') {
              setAttestation({file, url});
            }
            if (currentFile === 'bulletin') {
              setBulletin({file, url});
            }
          };

          reader.onerror = (e) => {
            console.log('e', e);
          };

          reader.readAsDataURL(file);
          e.target.value = null;
        }}
      />
      <StepFooter
        onPressNext={() => onSubmit()}
        next="/devis#success"
        //nextDisabled={invalidForm}
        fetching={updateValues.fetching}
        nextText="Envoyer"
        history={props.history}
        onCancel={props.onCancel}
      />
    </div>
  );
}
