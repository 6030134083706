import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../../actions';

import {infosStateSelect, currentUserStateSelect} from '../../selector';

import Infos from './component';

const InfosContainer = (props) => {
  const currentUser = useSelector(currentUserStateSelect);
  const infos = useSelector(infosStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  return (
    <Infos
      infos={infos}
      currentUser={currentUser}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default InfosContainer;
