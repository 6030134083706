import React, {useState} from 'react';

import {Col, Row, Button, Spinner} from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';

import ErrorLabel, {usePasswordCheck} from 'hooks/usePasswordCheck';

import TopNavbar from 'components/Navbars/TopNavbar';
import AppInput from 'components/AppInput';

import {Api} from 'api';

import './myspace-styles.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MonEspace(props) {
  const [old, setOld] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [openOldP, setOpenOldP] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmHasError, setConfirmError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [displayAlert, setDisplayAlert] = useState({
    display: false,
    text: '',
    error: false,
  });
  const {isValid: passwordValid} = usePasswordCheck(password);

  const onSubmit = async () => {
    try {
      if (password !== confirm) {
        setConfirmError(true);
      } else {
        setFetching(true);
        setConfirmError(false);
        // send to BE
        await Api().post('/auth/users/password', {
          currentPassword: old,
          newPassword: password,
          verifyPassword: confirm,
        });
        setOld('');
        setPassword('');
        setConfirm('');
        setDisplayAlert({
          display: true,
          text: 'Votre mot de passe a bien été modifié',
          error: false,
        });
      }
    } catch (e) {
      console.log('change pass error', e);
      setDisplayAlert({
        display: true,
        text: e?.message || 'Erreur de modification',
        error: true,
      });
    } finally {
      setFetching(false);
    }
  };

  return (
    <div className="app-container">
      <TopNavbar isConnected={props.currentUser !== null} />
      <div className="app-content">
        <div className="space-page-container">
          <h3 className="space-page-title-text">Modifier le mot de passe</h3>
          <span className="space-step-field-title">
            Saisissez votre mot de passe deux fois
          </span>

          <div className="space-step-form">
            <Row>
              <Col xs="12" sm="4">
                <AppInput
                  label="Mot de passe actuel"
                  value={old}
                  onChange={(t) => setOld(t)}
                  type={openOldP ? 'text' : 'password'}
                  leftAddOn={
                    <div
                      onClick={() => setOpenOldP(!openOldP)}
                      className="input-addon">
                      <img
                        alt="+"
                        src={
                          openOldP
                            ? require('assets/img/eye-b.png')
                            : require('assets/img/eye.png')
                        }
                      />
                    </div>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="4">
                <AppInput
                  label="Nouveau mot de passe"
                  value={password}
                  onChange={(t) => setPassword(t)}
                  type={openPassword ? 'text' : 'password'}
                  leftAddOn={
                    <div
                      onClick={() => setOpenPassword(!openPassword)}
                      className="input-addon">
                      <img
                        alt="+"
                        src={
                          openPassword
                            ? require('assets/img/eye-b.png')
                            : require('assets/img/eye.png')
                        }
                      />
                    </div>
                  }
                />
                {password !== '' && <ErrorLabel password={password} />}
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="4">
                <AppInput
                  label="Nouveau mot de passe"
                  value={confirm}
                  onChange={(t) => setConfirm(t)}
                  type={openConfirm ? 'text' : 'password'}
                  leftAddOn={
                    <div
                      onClick={() => setOpenConfirm(!openConfirm)}
                      className="input-addon">
                      <img
                        alt="+"
                        src={
                          openConfirm
                            ? require('assets/img/eye-b.png')
                            : require('assets/img/eye.png')
                        }
                      />
                    </div>
                  }
                />
                {confirmHasError && (
                  <h6 className="login-input-error">
                    Les mots de passes ne sont pas identiques
                  </h6>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="4">
                <Button
                  className="btn-round"
                  color="secondary"
                  onClick={(e) => {
                    // e.preventDefault();
                    onSubmit();
                  }}
                  size="lg"
                  disabled={!old || !passwordValid || !confirm}>
                  {fetching ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    'Sauvegarder'
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Snackbar
        open={displayAlert.display}
        onClose={() =>
          setDisplayAlert({display: false, text: '', error: false})
        }
        TransitionComponent={Fade}
        key={Fade.name}>
        <Alert
          onClose={() =>
            setDisplayAlert({display: false, text: '', error: false})
          }
          severity={displayAlert.error ? 'error' : 'info'}>
          {displayAlert.text}
        </Alert>
      </Snackbar>
    </div>
  );
}
