import React, {useEffect, useState} from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  // InputGroup,
  Label,
  Spinner,
} from 'reactstrap';

// core components
import AuthNavbar from 'components/Navbars/AuthNavbar';

import './login-styles.scss';

function LoginView(props) {
  useEffect(() => {
    document.body.classList.add('login-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
    };
  }, []);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [openPassword, setOpenPassword] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {username, password};
    props.actions.signin(payload);
  };

  return (
    <div className="app-container signin">
      <AuthNavbar />
      <div className="app-content bg-image login-content">
        <Col md="8">
          <Form action="" onSubmit={onSubmit} className="form" method="">
            <Card className="card-login card-plain">
              <CardBody>
                <h3 className="text-black text-bold auth-title">
                  Connectez-vous !
                </h3>
                <FormGroup>
                  <Label className="text-black" for="login-email">
                    Login
                  </Label>
                  <Input
                    id="login-email"
                    name="login-email"
                    placeholder=""
                    type="email"
                    value={username}
                    onChange={(e) => setUsername(e.currentTarget.value)}
                    className="custom-input"
                  />
                </FormGroup>
                <FormGroup className="password-input">
                  <Label className="text-black" for="pwd">
                    Mot de passe
                  </Label>
                  <Input
                    id="pwd"
                    name="pwd"
                    placeholder=""
                    type={openPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    className="custom-input"
                  />
                  <div
                    onClick={() => setOpenPassword(!openPassword)}
                    className="input-addon">
                    <img
                      alt="+"
                      src={
                        openPassword
                          ? require('assets/img/eye-b.png')
                          : require('assets/img/eye.png')
                      }
                    />
                  </div>
                </FormGroup>
              </CardBody>
              <CardFooter className="text-center">
                <Button
                  block
                  className="btn-round on-secondary"
                  color="secondary"
                  size="lg"
                  type="submit"
                  disabled={!username || !password || props.auth.fetching}>
                  {props.auth.fetching ? (
                    <Spinner size="sm" />
                  ) : (
                    <span> Se connecter </span>
                  )}
                </Button>
              </CardFooter>
              {props.auth.requestError && (
                <h6 className="auth-error">{props.auth.errorMessage}</h6>
              )}
              <div className="other-actions">
                <div
                  tag={'button'}
                  onClick={() => {
                    props.history.push('/auth/signup');
                  }}
                  className="bottom-action">
                  Je n'ai pas de compte
                </div>
                <div
                  onClick={() => {
                    props.history.push('/auth/forgot');
                  }}
                  className="bottom-action">
                  J'ai oublié mon mot de passe
                </div>
              </div>
            </Card>
          </Form>

          <CardFooter className="text-left">
            <div className="text-black" style={{marginTop: '19%'}}>
              <img
                width="100px"
                alt="..."
                src={require('assets/img/Picture1.png')}
              />{' '}
              <span className="text-black" style={{marginTop: '45%'}}>
                © Copyright 2021 AGMA - Tous Droits réservés -
                <a href=" https://agma.ma/mentions-legales-2/" target="_blank">
                  {' '}
                  Mentions légales
                </a>
              </span>
            </div>
          </CardFooter>

          {/*<div className="bottom-cgu">
            <span className="text-black">
              En continuant, vous acceptez les{' '}
              <a className="text-info" href="#/cgus">
                {' '}
                conditions générales d'utilisations{' '}
              </a>{' '}
              et notre{' '}
              <a className="text-info" href="#/cgus">
                {' '}
                politique de confidentialité{' '}
              </a>{' '}
            </span>
                </div>*/}
        </Col>
      </div>
    </div>
  );
}

export default LoginView;
