import {combineReducers} from 'redux';

/*
 * Import reducers
 */
import infos from './infos';
import confirm from './confirm';
import fichiers from './fichiers';

export default combineReducers({
  infos,
  confirm,
  fichiers,
});
