import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Api} from 'api';

import {setEmployer} from 'actions';

export function useCheckLink(emp) {
  const [redirection, setRedirection] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkLink() {
      try {
        setLoading(true);
        await Api().get(`/employers/${emp}/check`);
        dispatch(setEmployer(emp));
        setRedirection('signup');
      } catch {
        setRedirection('404');
      } finally {
        setLoading(false);
      }
    }

    checkLink();
  }, [dispatch, emp]);

  return {redirection, loading};
}
