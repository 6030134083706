import React from 'react';
import {Route, Redirect, Switch} from 'react-router';

import routes from './routes';

function Requests({match, location}) {
  return (
    <div>
      <Switch>
        {routes.map((route, i) => {
          if (location.pathname === '/sinistres') {
            return <Redirect key={route.path} to="/sinistres/list" />;
          }
          return (
            <Route
              key={i}
              path={`${match.path}${route.path}`}
              render={(props) => <route.component {...props} />}
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default Requests;
