import React from 'react';
import moment from 'lib/moment';

import StatusTag from 'components/StatusTag';

const statusText = {
  IN_PROGRESS: 'Devis en attente de confirmation',
  NOT_CONFIRMED: 'Devis en attente de confirmation',
  CONFIRMED_WITHOUT_PJ: 'Devis confirmé, manque PJ',
  CONFIRMED_WITH_PJ: 'Confirmé',
};

export const columns = [
  {
    title: 'N° Contrat',
    dataIndex: 'IdPolice',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">{info.IdPolice}</span>
      </div>
    ),
  },
  {
    title: 'N° Sinistre',
    dataIndex: 'IdSinistre',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">{info.IdSinistre}</span>
      </div>
    ),
  },
  {
    title: 'Date de Sinistre',
    dataIndex: 'DateSin',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">
          {info.DateSin ? moment(info.DateSin).format('L') : ''}
        </span>
      </div>
    ),
  },
  {
    title: 'Immatriculation',
    dataIndex: 'Matricule',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">{info.Matricule}</span>
      </div>
    ),
  },
  // {
  //   title: 'Garantie',
  //   dataIndex: 'code',
  //   renderItem: (info, props, cb) => (
  //     <div className="cell-container" onClick={cb}>
  //       <span className="table-txt">{info.code}</span>
  //     </div>
  //   ),
  // },
  {
    title: 'Montant',
    dataIndex: 'MtRegle',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">{`${
          info.MtRegle ? info.MtRegle + ' DH' : ''
        }`}</span>
      </div>
    ),
  },
  {
    title: 'Statut',
    dataIndex: 'Observation',
    renderItem: (info, props, cb) => (
      <div className="cell-container" onClick={cb}>
        <span className="table-txt">{info.Observation}</span>
      </div>
    ),
  },
  // {
  //   title: 'Statut',
  //   dataIndex: 'statut',
  //   renderItem: (info, props, cb) => (
  //     <div className="cell-container" onClick={cb}>
  //       <StatusTag
  //         label={statusText[info.statut]}
  //         status={`tag-${info.statut?.toLowerCase()}`}
  //       />
  //     </div>
  //   ),
  // },
];
