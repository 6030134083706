import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const NoticeModal = (props) => {
  const invalides = Object.keys(props.validations || {}).filter(
    (one) => props.validations[one],
  );
  const mapText = {
    ageInvalid: "L'âge du véhicule",
    plafondInvalid: 'La valeur vénale du véhicule',
    vdgInvalid: 'La valeur des glaces du véhicule',
    vnInvalid: 'La valeur neuve du véhicule',
    vvInvalid: 'La valuer vénale du véhicule',
    vn: 'Valeur neuve',
    vv: 'Valeur vénale',
    vdg: 'Valeur des glaces',
  };
  return (
    <Modal isOpen={props.display} toggle={props.onCancel}>
      <ModalHeader className="notice-header">
        <span className="modal-title"> Notice </span>
      </ModalHeader>
      <ModalBody>
        {props.validations ? (
          <>
            <p>Vous ne pouvez pas continuer à cause de : </p>
            <ul>
              {invalides.map((inv, i) => (
                <li key={inv}>{`${mapText[inv]}`}</li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <p>{`${props.message} :`}</p>
            {props.required ? (
              <ul>
                {props.required.map((inv, i) => (
                  <li key={inv}>{`${mapText[inv]}`}</li>
                ))}
              </ul>
            ) : null}
          </>
        )}
      </ModalBody>
      <ModalFooter className="notice-footer">
        <div
          className="modal-close-btn"
          tag="button"
          color="secondary"
          onClick={props.onCancel}>
          Fermer
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default NoticeModal;
