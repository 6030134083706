import {combineReducers} from 'redux';

/*
 * Import reducers
 */
import user from './user';
import vehicule from './vehicule';
import fichiers from './fichiers';
import garanties from './garanties';

export default combineReducers({
  user,
  vehicule,
  fichiers,
  garanties,
});
