import {
  CLEAR_DECLARATION_FORM,
  UPDATE_SINISTER_INFO_FORM,
  UPDATE_FILES_FORM,
} from './actionsTypes';

export const clearDeclarationForm = () => ({
  type: CLEAR_DECLARATION_FORM,
});

export const updateUserForm = (payload) => ({
  type: UPDATE_SINISTER_INFO_FORM,
  payload,
});

export const updateFilesForm = (payload) => ({
  type: UPDATE_FILES_FORM,
  payload,
});
