// import front-end config
import {CLEAR_CREATION_FORM, UPDATE_USERDATA_FORM} from '../actionsTypes';
import {LOGOUT_SUCCESS} from 'actionsTypes';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USERDATA_FORM:
      return {...state, ...action.payload};

    case CLEAR_CREATION_FORM:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
