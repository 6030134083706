import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';

import {Api} from 'api';

import {setCurrentDemand} from 'modules/Demandes/actions';

import {createUrl, devisById} from 'modules/Devis/api/endpoints';
import {setCreatedDevis, setDefaultGaranties} from 'modules/Devis/actions';
import {fichiersStateSelect} from 'modules/Devis/selector';
import {detailsStateSelect} from 'modules/Demandes/selector';

export function useCreateDevis() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const fichiers = useSelector(fichiersStateSelect);

  async function createDevis(payload, redirect, edit, cb) {
    try {
      setFetching(true);
      setError(null);
      let data;
      const id = detailsStateSelect.current?._id || fichiers.devis?._id;
      if (id && edit) {
        data = await Api().put(devisById(id), payload);
      } else {
        data = await Api().post(createUrl(), payload);
      }

      setFetching(false);
      setData(data);
      dispatch(setCreatedDevis(data));
      if (redirect) {
        dispatch(push(redirect));
        dispatch(setDefaultGaranties(null));
      }
      if (edit) {
        dispatch(setCurrentDemand(data));
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      setFetching(false);
      setError(err);
    }
  }

  return [{fetching, error, data}, createDevis];
}
