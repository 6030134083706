// Api
import {Api} from 'api';

// some endpoints
import {meUrl, uploadUrl} from 'api/endpoints';

import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_FAILED,
  SET_CURRENT_USER,
  LOGOUT_SUCCESS,
  SET_EMPLOYER,
} from 'actionsTypes';

export const getCurrentUserRequest = () => ({
  type: GET_CURRENT_USER_REQUEST,
});

export const getCurrentUserFailed = (payload) => ({
  type: GET_CURRENT_USER_FAILED,
  payload,
});

export const setEmployer = (payload) => ({
  type: SET_EMPLOYER,
  payload,
});

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const getMe = () => {
  return (dispatch) => {
    dispatch(getCurrentUserRequest());
    Api()
      .get(meUrl())
      .then((data) => {
        if (data) {
          dispatch(setCurrentUser(data));
        } else {
        }
      })
      .catch((err) => {
        dispatch(getCurrentUserFailed());
        // reportError("get me error", err);
      });
  };
};

export const uploadFiles = (payload) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await Api().post(uploadUrl(), payload, true);
      resolve(data);
    } catch (err) {
      //   reportError("upload files error ", err);
      reject(err);
    }
  });

  return promise;
};

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});
