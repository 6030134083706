// import front-end config
import {CLEAR_CREATION_FORM, SET_CREATED_DEVIS} from '../actionsTypes';
import {LOGOUT_SUCCESS} from 'actionsTypes';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  devis: null,
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function fichiers(state = initialState, action) {
  switch (action.type) {
    case SET_CREATED_DEVIS:
      return {...state, devis: action.payload};

    case CLEAR_CREATION_FORM:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
