import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

/*
 * Import reducers
 */
import app from './appReducer';
import auth from 'modules/Auth/reducers';
import devis from 'modules/Devis/reducers';
import demandes from 'modules/Demandes/reducers';
import declaration from 'modules/Declaration/reducers';
import sinistres from 'modules/Sinistres/reducers';

const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    devis,
    demandes,
    declaration,
    sinistres,
  });

export default createReducers;
