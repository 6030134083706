import {useState, useEffect} from 'react';

import downloadApi from 'api/downloadApi';

export function useDownloadPdf(url, cb) {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const pdfUrl = await downloadApi(url);

        setData(pdfUrl);
        if (cb) {
          cb(pdfUrl);
        }
      } catch {
        // maybe display an error message
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [cb, url]);

  return {fetching, data};
}
