import React from 'react';

// reactstrap components

import {useCheckAuth} from 'hooks/useCheckAuth';

import moment from 'lib/moment';

// core components
import TopNavbar from 'components/Navbars/TopNavbar';

import '../../sinistres-styles.scss';
import 'assets/css/listviews.css';

function RequestDetails(props) {
  useCheckAuth();
  const {current} = props.details;

  return (
    <div className="app-container">
      <TopNavbar isConnected={props.currentUser !== null} />
      <div className="app-content requests-list">
        <h3 className="demande-title-text">Détails Sinistre</h3>
        <div>
          <div className="info-view">
            <h5>N° Contrat:</h5>
            <div>
              <span className="info-value">{current?.IdPolice}</span>
            </div>
          </div>
          <div className="info-view">
            <h5>N° Sinistre:</h5>
            <div>
              <span className="info-value">{current?.IdSinistre}</span>
            </div>
          </div>
          <div className="info-view">
            <h5>Date de Sinistre:</h5>
            <div>
              <span className="info-value">
                {current?.DateSin ? moment(current.DateSin).format('L') : ''}
              </span>
            </div>
          </div>
          <div className="info-view">
            <h5>Immatriculation:</h5>
            <div>
              <span className="info-value">{current?.Matricule}</span>
            </div>
          </div>
          <div className="info-view">
            <h5>Montant:</h5>
            <div>
              <span className="info-value">{`${
                current.MtRegle ? current.MtRegle + ' DH' : ''
              }`}</span>
            </div>
          </div>
          <div className="info-view">
            <h5>Statut:</h5>
            <div>
              <span className="info-value">{current?.Observation}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestDetails;
