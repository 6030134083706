import React, {useState} from 'react';

// reactstrap components

import moment from 'lib/moment';
import {Capitalize} from 'lib/capitalize';
import {useCheckAuth} from 'hooks/useCheckAuth';
import useFetchElement from 'hooks/useFetchElement';

// endpoints
import {requestByIdUrl} from '../../api/endpoints';

// core components
import TopNavbar from 'components/Navbars/TopNavbar';
import StatusTag from 'components/StatusTag';

import Report from '../report';
import Fichiers from '../fichiers';

import '../../requests-styles.scss';
import 'assets/css/elementDetails.css';

const statusText = {
  IN_PROGRESS: 'Devis en attente de confirmation',
  NOT_CONFIRMED: 'Devis en attente de confirmation',
  CONFIRMED_WITHOUT_PJ: 'Devis confirmé, manque PJ',
  CONFIRMED_WITH_PJ: 'Confirmé',
};

const filesNames = {
  cgRecto: 'Carte grise Recto',
  cgVerso: 'Carte grise Verso',
  cnieRecto: 'CNIE Recto',
  cnieVerso: 'CNIE Verso',
  permisRecto: 'Permis de conduire Recto',
  permisVerso: 'Permis de conduire Verso',
  cessionSalaire: 'Cession de délégation sur salaire',
  attTravail: 'Attestation de travail ou Badge',
  bullSoucription: 'Bulletin de souscription',
};

function RequestsList(props) {
  useCheckAuth();
  const {current} = props.details;
  const {data: request /*, fetching */} = useFetchElement(
    current,
    requestByIdUrl(current.id),
    // () => props.actions.setCurrentDemand(request),
  );

  const [displayFiles, setDisplayFiles] = useState(
    current?.statut === 'CONFIRMED_WITHOUT_PJ',
  );

  return (
    <div className="app-container">
      <TopNavbar isConnected={props.currentUser !== null} />
      <div className="app-content requests-list">
        <h3 className="demande-title-text">Détail de la demande </h3>
        <div>
          <h3 className="detail-title-text">{`Demandes > ${Capitalize(
            current?.userData?.name.first,
          )} ${Capitalize(current?.userData?.name.last)}`}</h3>
          <div className="info-view">
            <h5>Date de la demande:</h5>
            <div>
              <span className="info-value">
                {moment(current?.created_at).format('lll')}
              </span>
            </div>
          </div>
          <div className="info-view">
            <h5>Statut de la demande:</h5>
            <div className="space-left status-employer">
              <StatusTag
                label={statusText[current?.statut]}
                status={`tag-${current?.statut?.toLowerCase()}`}
              />
            </div>
          </div>
          <div className="info-view">
            <h5>Convention:</h5>
            <div>
              <span className="info-value">
                {current?.conventionData?.name}
              </span>
            </div>
          </div>
          <div>
            <div className="row-info">
              <div className="info-view">
                <h5>Type:</h5>
                <div>
                  <span className="info-value">
                    {current?.type === 'pack' ? 'Pack' : 'à la Carte'}
                  </span>
                </div>
              </div>
              {current?.type === 'pack' && (
                <div className="info-view">
                  <h5>Nom du pack:</h5>
                  <div>
                    <span className="info-value">
                      {current?.packData?.name}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="row-info">
              <div className="info-view">
                <h5>Assuré:</h5>
                <div>
                  <span className="info-value">
                    {`${Capitalize(
                      current?.interestedData?.name.first,
                    )} ${Capitalize(current?.interestedData?.name.last)}`}
                  </span>
                </div>
              </div>
              <div className="info-view">
                <h5>Employeur:</h5>
                <div>
                  <span className="info-value">
                    {current?.employerData?.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="row-info">
              <div className="info-view">
                <h5>Matricule:</h5>
                <div>
                  <span className="info-value">
                    {current?.vehiculeData?.immatriculation}
                  </span>
                </div>
              </div>
              <div className="info-view">
                <h5>Motorisation/PF:</h5>
                <div>
                  <span className="info-value">
                    {`${current?.vehiculeData.motorisation}/${current?.vehiculeData.pf}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="info-view">
            <h5>Mode de paiement:</h5>
            <div>
              <span className="info-value">{current?.paymentMode}</span>
            </div>
          </div>
          {current?.filesUrl &&
            Object.keys(current?.filesUrl).map((file) => (
              <div className="info-view">
                <h5>{filesNames[file]}:</h5>
                <div tab="button" onClick={() => {}}>
                  <span className="info-value download-l">Télécharger</span>
                </div>
              </div>
            ))}

          <Report
            statut={current?.statut}
            onConfirm={() => {
              setDisplayFiles(true);
            }}
          />

          {current?.statut === 'CONFIRMED_WITHOUT_PJ' && displayFiles && (
            <Fichiers onConfirm={() => {}} />
          )}
        </div>
      </div>
    </div>
  );
}

export default RequestsList;
