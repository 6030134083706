import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../../actions';

import {authStateSelect, employerStateSelect} from '../../selector';

import SignupView from './component';

const SignupViewContainer = (props) => {
  const auth = useSelector(authStateSelect);
  const employer = useSelector(employerStateSelect);
  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators({...actions}, dispatch);
  return (
    <SignupView
      employer={employer}
      auth={auth}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default SignupViewContainer;
