import {uniqBy} from 'lodash';

// import front-end config
import {
  CLEAR_STEP3_FORM,
  CLEAR_CREATION_FORM,
  SAVE_DEFAULT_ENABLED_CODES,
  SET_INTERDEPENDANCES,
  UPDATE_GARANTIES_FORM,
  SET_WITH_G11,
} from '../actionsTypes';
import {LOGOUT_SUCCESS} from 'actionsTypes';

// import {REHYDRATE} from 'redux-persist/lib/constants';

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  defaultEnabled: null,
  interdeps: [],
  assistance: null,
  configType: '',
  packsWithG11: {},
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function garanties(state = initialState, action) {
  switch (action.type) {
    case SAVE_DEFAULT_ENABLED_CODES:
      return {
        ...state,
        defaultEnabled: action.payload
          ? {
              ...state.defaultEnabled,
              ...action.payload,
            }
          : null,
      };

    case SET_INTERDEPENDANCES:
      return {
        ...state,
        interdeps: uniqBy(action.payload.concat(state.interdeps), '_id'),
      };

    case UPDATE_GARANTIES_FORM:
      return {...state, ...action.payload};

    case SET_WITH_G11:
      return {
        ...state,
        packsWithG11: {...state.packsWithG11, ...action.payload},
      };

    case CLEAR_STEP3_FORM:
    case CLEAR_CREATION_FORM:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
