// routes components
import Login from './containers/login';
import Signup from './containers/signup';
import ForgotPassword from './containers/forgotPassword';
import SignupSuccess from './containers/signupSuccess';
import ForgotSuccess from './containers/forgotSuccess';
import Landing from 'modules/Landing';

const routes = [
  {
    path: '/landing',
    component: Landing,
    exact: true,
  },
  {
    path: '/signin',
    component: Login,
    exact: true,
  },
  {
    path: '/signup',
    component: Signup,
    exact: true,
  },
  {
    path: '/forgot',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/success',
    component: SignupSuccess,
    exact: true,
  },
  {
    path: '/forgotSuccess',
    component: ForgotSuccess,
    exact: true,
  },
];

export default routes;
