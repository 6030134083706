import React from 'react';

import {Button, Spinner} from 'reactstrap';

const StepFooter = ({
  previous,
  previousDisplay,
  nextDisabled,
  next,
  fetching,
  ...props
}) => {
  return (
    <div className="creation-footer">
      {previousDisplay && (
        <Button
          className="btn-round"
          color="default"
          onClick={(e) => {
            e.preventDefault();
            if (props.onPressPrevious) {
              props.onPressPrevious();
            }
            props.history.goBack();
          }}
          size="lg"
          disabled={!previous}>
          {props.previousText || 'Précédent'}
        </Button>
      )}

      <Button
        className="btn-round space-left"
        color="secondary"
        onClick={(e) => {
          // e.preventDefault();
          if (props.onPressNext) {
            props.onPressNext();
          } else {
            props.history.push(next);
          }
        }}
        size="lg"
        disabled={nextDisabled || fetching}>
        {fetching ? (
          <Spinner size="sm" color="light" />
        ) : (
          props.nextText || 'Continuer'
        )}
      </Button>
    </div>
  );
};

StepFooter.defaultProps = {
  previous: true,
  previousDisplay: true,
};

export default StepFooter;
