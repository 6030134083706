import React from 'react';
import {useDispatch} from 'react-redux';

import StepFooter from '../../stepFooter';

// reactstrap components

// core components
import TopNavbar from 'components/Navbars/TopNavbar';
import CreationStepper from '../../creationStepper';

import {clearCreationForm} from '../../actions';

import '../../devis-styles.scss';

function DevisSuccess(props) {
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(clearCreationForm());
    props.history.push('/');
  };

  return (
    <div className="app-container">
      <TopNavbar isConnected={props.currentUser !== null} />
      <div className="app-content">
        <CreationStepper activeStep={5} />
        <div>
          <div className="step-container text-center">
            <h3 className="page-title-text no-bottom">
              Nous avons bien reçu votre demande
            </h3>
          </div>
          <div className="text-center">
            <h5>Votre contrat et attestation sont en cours de préparation.</h5>
          </div>
          <div className="text-center bottom-10">
            <h5>
              Un gestionnaire prendra contact avec vous dans les 48 heures.
            </h5>
          </div>
          <div className="text-center">
            <img
              alt="=>"
              src={require('assets/img/devisSuccess.jpg')}
              className="devis-success"
            />
          </div>
          <StepFooter
            previousDisplay={false}
            onPressNext={() => onSubmit()}
            next="/"
            nextText="Terminer"
            //nextDisabled={invalidForm}
            //fetching={values.fetching}
            history={props.history}
            onCancel={props.onCancel}
          />
        </div>
      </div>
    </div>
  );
}

export default DevisSuccess;
